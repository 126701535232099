import React, { useState, useEffect } from 'react';
import Modal from '../Modal';

const NewTermModal = ({blackListFields, handleCreate, initialTerm={text: ''}, postOrPatch='POST'}) => {
const [term, setTerm] = useState(initialTerm)
const [selectedFields, setSelectedFields] = useState([])
const [selectAll, setSelectAll] = useState(false)
const [showCheckboxError, setShowCheckboxError] = useState(false)
const [showEmptyFieldError, setShowEmptyFieldError] = useState(false)

useEffect(() => {
  setSelectAll(false)
  handleSettingSelectedFields()
}, [])

const handleSettingSelectedFields = () => { 
  let fields = cloneFields()
  
  if (postOrPatch == 'POST') {
    setSelectedFields(fields)
  } else {
    term.fields.forEach( (field) => {
      fields.find(f => f.name === field.type).checked = field.checked
    })
    setSelectedFields(fields)
  }
}

const cloneFields = () => {
  return JSON.parse(JSON.stringify(blackListFields))
}

const handleCreatingTerms = () => {
  if (!isEmptyTerm() && isCheckboxSelected() ) {
    setShowEmptyFieldError(false)
    setShowCheckboxError(false)
    let selectedFieldNames = selectedFields.filter(field => field.checked).map(field => field.name)
    handleCreate(term.text, selectedFieldNames, postOrPatch)
    Modal.close()
  }
}

const isCheckboxSelected = () => {
  let isSelected = selectedFields.filter(field => field.checked).length > 0
  if (!isSelected) {
    setShowCheckboxError(true)
  }
  return isSelected
}

const isEmptyTerm = () => {

  let isEmpty = term.text.length == 0
  if (isEmpty) {
    setShowEmptyFieldError(true)
  }
  return isEmpty
}

const renderCheckboxes = () => {
    let checkboxes = renderBlackListFieldsCheckboxes()
    checkboxes.unshift(renderSelectAllCheckbox())
    return checkboxes
  }

  const renderSelectAllCheckbox = () => {
    return (
      <div key={"select-all"} className="checkbox-container">
        <input type="checkbox" id="modal-select-all" checked={selectAll} value={selectAll} onChange={checkAllBoxes}/>
        <label htmlFor="modal-select-all">Select all</label>
      </div>
    )
  }

  const checkAllBoxes = (e) => {
    setSelectedFields(selectedFields.map(field => { return {name: field.name, checked: !selectAll} }))
    setSelectAll(!selectAll);
  }

  const renderBlackListFieldsCheckboxes = () => {
    return blackListFields
      .map((field, index) => {
      let displayName = capitalize(field.name); 
      if (field.name === 'bio') {
        displayName = 'About'
      }

      let name = field.name;
      let isChecked = isFieldCheckboxChecked(name);


      return (
        <div key={index} className="checkbox-container">
          <input type="checkbox" id={name} name={name} checked={isChecked} value={isChecked} onChange={() => handleCheckboxChange(name)}/>
          <label htmlFor={name}>{displayName}</label>
        </div>
      )
    })
  }

  const handleCheckboxChange = (name) => {
    let updatedSelectedFields = selectedFields.map(field => {
      if (field.name === name) {
        return {name: field.name, checked: !field.checked}
      } else {
        return field
      }
    })

    setSelectedFields(updatedSelectedFields)
  }

  const isFieldCheckboxChecked = (name) => {
    return selectedFields.find(field => field.name === name)?.checked
  }

  const capitalize = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  const renderInputField = () => {
    let isDisabled = (postOrPatch === 'PATCH')
    return <input type="text" disabled={isDisabled} placeholder="Please enter the term" value={term.text} onChange={(e) => handleSetTerm(e.target.value)}/>
  }

  const handleSetTerm = (name) => {
    const termClone = JSON.parse(JSON.stringify(term));
    termClone.text = name;
    setTerm(termClone);
  }

  return (
    <div className="reveal-modal small unwanted-accounts-modal" data-reveal>
      <div className="modal-body">
        <div className='modal-close'>
          <a href='#' className='cancel-modal'>
            <img src="https://public.cureus.com/close_x.png"/>
          </a>
        </div>
        <div className="modal-content middling">
          <div className="modal-header text-center">
            <h5>Add single word/phrase</h5>
          </div>
          <hr/>

          <div className='modal-form'>
            <div className="row">
              <div className="small-12 columns">
                <p>Please enter the term you want to add to the blacklist and select where the term should be blacklisted.</p>
              </div>
            </div>

            <div className="row">
              <div className="small-10 columns">
                <div className="row">
                  <div className="input-container">
                    <label>Term</label>
                    {renderInputField()}
                    <p className='id-error-message'></p>
                    {showCheckboxError && <p class="id-error-message">At least one checkbox must be selected</p>}
                    {showEmptyFieldError && <p class="id-error-message">Term cannot be empty</p>}
                  </div>
                </div>
                <div className="row">
                  <ul>
                    {renderCheckboxes()}
                  </ul>
                </div>
              </div>
              <div className="small-2 columns">
                <button className="button primary" onClick={handleCreatingTerms}>Add</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default NewTermModal