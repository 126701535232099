import React, { useState, useEffect } from 'react';
import CoauthorMain from '../Dashboard/CoauthorTool/CoauthorMain';
import { Cureus } from '../../Cureus';
import ReviewerActivityMain from '../Dashboard/ReviewsDashboard/ReviewerActivity/ReviewerActivityMain';
import PeerReviewProgress from '../Dashboard/ReviewsDashboard/PeerReviewProgress';
import Modal from '../Modal';
import NewModal from '../Dashboard/ReviewsDashboard/NewModal';
import RemindModal from '../Dashboard/ReviewsDashboard/RemindModal';
import RemindSpecificModal from '../Dashboard/ReviewsDashboard/RemindSpecificModal';

const ArticleExpanded = ({ article, articleStates, authorships, authorshipEndpoint, expanded, setExpanded, isSubmitter, coauthorConfirmedEmphasisBox, renderLock, renderStateSteps, renderBanner, renderHamburgerMenu, renderEditorMessageIcon, renderPrimaryActionButton, renderType, renderTitle }) => {

  const [canSendReminders, setcanSendReminders] = useState(article.canSendReminders);
  const [reviews, setReviews] = useState(article.reviews);

  const renderButtons = (small) => {
    if(article.articleState !== 'published' && isSubmitter) {
      return (
        <React.Fragment>
            {renderHamburgerMenu(true, small)}
            <div className="secondary-button expand expanded-button" onClick={() => setExpanded(!expanded)}>
              <div className="caret-container">
                <i className="fas fa-caret-down expanded-button"/>
              </div>
              <div className="caret-container">
                <i className="fas fa-caret-up expanded-button"/>
              </div>
            </div>
        </React.Fragment>
      )
    } else {
      return (
        <a className="secondary-button menu">
          <span><img src="https://public.cureus.com/dashboard-menu.png"/></span>
        </a>
      )
    }
  }

  const renderCoauthorsTable = () => {
    if (article.articleState != 'published') {
      return (
        <CoauthorMain
        key={ "article_authors_row_" + article.id }
        articleId={ article.id }
        articleState={ article.articleState }
        deferredForPreferred={ article.deferredForPreferred }
        pastEditorApproval={ article.pastEditorApproval }
        authorships={ authorships }
        authorshipEndpoint={ authorshipEndpoint }
        editAuthorsPath={article.editAuthorsPath}
        coauthorConfirmedEmphasisBox={coauthorConfirmedEmphasisBox}
        />
      )
    }
  }

  const handleInvite = () => {
    Modal.open({
      children: <NewModal
      article={ article }
      defaultParams={{article_id: article.id}}
      endpointUrl={article.endpointUrl}
      inReview={article.articleState === "in_review"}
      handleReviewerAdd={handleReviewerAdd}
      specialtyOptions={ article.specialtyOptions }
      countryOptions={ article.countryOptions }
      affiliationNameQueryUrl={ article.affiliationNameQueryUrl }
      isAuthorships={article.isAuthorships}
      />
    })
  }

  const handleReviewerAdd = () => {
    var newReviews = Object.assign({}, reviews)
    newReviews.total = newReviews.total + 1
    newReviews.invited = newReviews.invited + 1
    setReviews(newReviews)
  }

  const openRemindModal = (e) => {
    if(article.remindSpecficModal && e.target.id === 'invited') {
      Modal.open({
        children: <RemindSpecificModal
                    article={article}
                    reviews={reviews}
                    reviewershipTargetGroup={e.target.id}
                    canSendReminders={canSendReminders}
                    disableSendReminders={disableSendReminders}
                    invited={(e.target.id === 'invited' || e.target.id === 'reinvited') ? true : false}
                    />
      })
    } else {
      Modal.open({
        children: <RemindModal
                    article={article}
                    reviews={reviews}
                    reviewershipTargetGroup={e.target.id}
                    canSendReminders={canSendReminders}
                    disableSendReminders={disableSendReminders}
                    invited={(e.target.id === 'invited' || e.target.id === 'reinvited') ? true : false}
                    />
      })
    }
  }

  const disableSendReminders = (reviewer_state) => {
    var newCanSendRemindersState = Object.assign({}, canSendReminders)
    newCanSendRemindersState[reviewer_state] = false
    setcanSendReminders(newCanSendRemindersState)
  }

  const renderReviewProgressTable = () => {
    if(article.reviewsState) {
      return (
        <div className="small-12 columns bottom-expanded-container">
          <PeerReviewProgress
            article={article}
            reviews={reviews}
            handleReviewerAdd={handleReviewerAdd}
            handleInvite={handleInvite}
            openRemindModal={openRemindModal}
          />
        </div>
      )
    }
  }

  const renderReviewActivityTable = () => {
    if(article.reviewsState) {
      return (
        <div className="small-12 columns bottom-expanded-container">
          <ReviewerActivityMain 
            article={article}
            openRemindModal={openRemindModal}
            handleInvite={handleInvite}
          />
        </div>
      )
    }
  }

  const smallView = () => {
    return (
      <div className="small-12 columns expanded show-for-small-only">
        <div className="small-12 columns article-box">
          {renderEditorMessageIcon()}
          <div className="row title-container">
            <div className="small-12 columns">
              {renderTitle()}
            </div>
          </div>
          <div className="row">
            <div className="small-12 columns">
              {renderType()}
            </div>
          </div>
          <div className="small-view-container">
            {renderStateSteps()}
          </div>
          <div className="small-view-container">
            <div className="small-12 columns text-left">
              <div className="small-12 columns no-padding">
                {renderPrimaryActionButton()}
              </div>
              <div className="small-12 columns no-padding" style={{marginTop: '11px'}}>
                {renderButtons('small')}
              </div>
            </div>
          </div>
          {renderBanner()}
        </div>
        <div className="small-12 columns bottom-expanded-container">
            {renderCoauthorsTable()}
        </div>
        {renderReviewProgressTable()}
        {renderReviewActivityTable()}
      </div>
    )
  }

  return (
    <React.Fragment>
      {smallView()}
    <div className="small-12 columns expanded hide-for-small">
      <div className="small-12 columns article-box">
      {renderEditorMessageIcon()}
      <div className="row title-container">
          <div className="large-8 medium-8 small-8 columns">
            {renderTitle()}
          </div>
          <div className={article.articleState === 'blocked'? "small-1 columns text-right" : "large-4 medium-4 small-4 columns text-right"}>
            {renderPrimaryActionButton()}
          </div>
        </div>
        <div className="row">
          <div className="large-9 medium-8 small-6 columns">
            {renderType()}
          </div>
          <div className="large-3 medium-4 small-6 columns text-right">
            {renderButtons()}
          </div>
        </div>
        {renderStateSteps()}
        {renderBanner()}
      </div>
      <div className="small-12 columns bottom-expanded-container">
        {renderCoauthorsTable()}
      </div>
      {renderReviewProgressTable()}
      {renderReviewActivityTable()}
    </div>
    </React.Fragment>
  )

}

export default ArticleExpanded;