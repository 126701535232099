var createReactClass = require('create-react-class');
import React from 'react';

const EditorDropdown = createReactClass({
  getInitialState: function() {
    return({
      selected: 'default'
    })
  },

  mapEditors: function() {
    var self = this;
    const sortedEditors = self.props.editors.sort((a, b) => a.first_name > b.first_name ? 1 : -1)
    return sortedEditors.map(function(editor) {
      var isJunior = editor.user_type.startsWith('Junior') ? '(J) ' : ''
      var specialty = self.props.type === 'Approval' ? editor.specialty : ''
      return <option key={editor.id + '_option'} value={editor.id}>{isJunior + editor.full_name + specialty}</option>
    });
  },

  handleChange: function(e) {
    this.setState({selected: e.target.value})
  },

  addEditor: function() {
    var self = this;
    var type = this.props.type.toLowerCase();
    var junior_editor = type == "copy" ? self.state.selected : null
    var assigned;
    if (self.props.type === 'Approval' || self.props.type === 'channel_approval' || self.props.type === 'channel_preview') {
      assigned = true;
    }
    self.setState({selected: 'default'}, self.props.addEditor({user_id: self.state.selected, type: type, junior_editor: junior_editor, assigned: assigned}))
  },

  renderSenior: function() {
    if(this.props.preferredArticle) {
      return 'Senior '
    } else {
      return ''
    }
  },

  renderType: function() {
    if(this.props.type.includes("channel")) {
      var firstWord = this.props.type.split('_')[0]
      return firstWord.charAt(0).toUpperCase() + firstWord.slice(1) + (this.props.type === 'channel_screening' ? ' Screener' : ' Editor');
    } else {
      return this.props.type + ' Editor'
    }
  },

  render: function() {
    return(
      <div className='row'>
        <div className='large-8 columns'>
          <select value={this.state.selected}  onChange={this.handleChange}>
            <option value='default'>{'Select ' + this.renderSenior() + this.renderType()}</option>
            {this.mapEditors()}

          </select>
        </div>
        <div className='large-2 columns end'>
          <input type='button' className='button' onClick={this.addEditor} value='Add' disabled={this.state.selected === 'default'}/>
        </div>
      </div>
    )
  }
})

export default EditorDropdown;