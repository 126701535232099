import React from 'react';

import {Cureus} from '../../Cureus';
import Modal from '../../components/Modal';
import EmailReminderModal from '../../components/AdminEmailReminder/EmailReminderModal';
import EditorPreviewApprovalReminderModal from '../../components/AdminEmailReminder/EditorPreviewApprovalReminderModal';

Cureus.Admin.MultipleReminders = (function($, _) {
  var init = function(opts) {
    new MultipleReminders(opts);
  }

  var MultipleReminders = function(opts) {
    this.$tabPane = $(opts.tabPaneSelector);
    this.$table = this.$tabPane.find("table");
    this.$nav = $(opts.navSelector);
    this.$btn = $(opts.btnSelector);
    this.$selectAll = this.$table.find("th input[type='checkbox']");
    this.$checkboxes = this.$table.find("td input[type='checkbox']");

    this.templateName = opts.templateName;
    this.templatePath = opts.templatePath;
    this.createPath = opts.createPath;
    this.async = opts.async
    this.canSelectOnlyOneCheckbox = opts.canSelectOnlyOneCheckbox;
    this.aeLeaderBoard = opts.aeLeaderBoard;
    this.tableName = opts.tableName;

    if (opts.noNavSelector) {
      this.bindModalOpen();
    }
    this._bindEvents();
  }

  _.extend(MultipleReminders.prototype, {
    _bindEvents: function() {
      var self = this;
      self.$selectAll.on("click", function() {
        if(self.$selectAll.is(":checked")) {
          self.checkAll();
        } else {
          self.unCheckAll();
        }
        self.updateButton();
      });

      self.$checkboxes.on("click", function() {
        if(self.allChecked()) {
          self.checkSelectAll();
        } else {
          self.unCheckSelectAll();
        }
        self.updateButton();
      });

      self.$nav.on("hidden.bs.tab", function() {
        self.unCheckAll();
        self.unCheckSelectAll();
        self.updateButton();
        if(!self.tabActive()) {
          self.$btn.off("click");
        }
      });

      self.$nav.on("shown.bs.tab", function() {
        if(self.tabActive()) {
          self.bindModalOpen();
        }
      });

      if(self.tabActive()) {
        self.bindModalOpen();
      }
    },

    tabActive: function() {
      return this.$tabPane.hasClass("active");
    },

    bindModalOpen: function() {
      var self = this;
      self.$btn.on("click", function() {
        if(!self.$btn.hasClass("disabled")) {
          self.openModal();
        }
      });
    },


    openModal: function() {
      var self = this;
      
      $('.modal-spinner').show();
      
      $.get(self.templatePath, {
        templateData: {resource_ids: self.resourceIds()},
        templateName: self.templateName,
        aeLeaderBoard: self.aeLeaderBoard
      }, function(data) {
        $('.modal-spinner').hide();
        Modal.open({
          children: self.selectEmailModal(data)
        });
      });
    },


    resourceIds: function() {
      return this.getCheckedCheckboxes().map(function(i, el) {
        return $(el).data("resource-id");
      }).toArray().join(",");
    },

    selectEmailModal: function(data) {
      let props = {
        templateName: this.templateName,
        templateData: {
          resource_ids: this.resourceIds(),
          ...(this.tableName ? { table_name: this.tableName } : {})
        },
        createPath: this.createPath,
        data: data,
        async: this.async,
        unCheckSingles: this.unCheckAll.bind(this),
        unCheckAll: this.unCheckSelectAll.bind(this)
      }

      switch (this.templateName) {
        case "EditorPreview":
          return <EditorPreviewApprovalReminderModal {...props} />
        case "EditorApproval":
          return <EditorPreviewApprovalReminderModal {...props} />
        default:
          return <EmailReminderModal {...props}/>
      }
    },

    checkSelectAll: function() {
      this.$selectAll.attr("checked", "checked");
    },

    unCheckSelectAll: function() {
      this.$selectAll.removeAttr("checked");
    },

    checkAll: function() {
      this.$checkboxes.prop('checked', true)
    },

    unCheckAll: function() {
      this.$checkboxes.prop('checked', false)
    },

    getCheckedCheckboxes: function() {
      return this.$table.find("td input[type='checkbox']:checked");
    },

    allChecked: function() {
      return this.getCheckedCheckboxes().length == this.$checkboxes.length;
    },

    anyChecked: function() {
      return this.getCheckedCheckboxes().length > 0;
    },

    enableButton: function() {
      this.$btn.removeClass("disabled");
    },

    disableButton: function() {
      this.$btn.addClass("disabled");
    },

    disableOtherCheckboxes: function() {
      if (this.canSelectOnlyOneCheckbox) {
        this.$checkboxes.not(":checked").attr("disabled", "disabled");
      }
    },

    enableOtherCheckboxes: function() {
      if (this.canSelectOnlyOneCheckbox) {
        this.$checkboxes.removeAttr("disabled");
      }
    },

    updateButton: function() {
      if(this.anyChecked()) {
        this.enableButton();
        this.disableOtherCheckboxes();
      } else {
        this.disableButton();
        this.enableOtherCheckboxes();
      }
    }
  });

  return { init: init };
})(jQuery, _);
