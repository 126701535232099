import React from 'react';
import DynamicJqueryTable from '../Shared/DynamicJqueryTable';

const columnsConfig = [
  { title: 'Submission date', data: 'submission_date', width: '200px' },
  { 
    title: 'Approved for peer review', 
    data: 'approved_for_review_date',
    width: '220px' 
  },
  { title: 'Tier', data: 'tier', width: '160px' },
  {
    title: 'Article ID',
    data: 'article_id',
    width: '125px',
    render: (row) => {
      return `<a class='email' target='_blank' href='/admin/articles/${row.article_id}'>${row.article_id}</a>`;
    }
  },
  {
    title: 'Submitter',
    data: 'submitter_id',
    render: (row) => {
      return `<a class='email' target='_blank' href='/admin/users/${row.submitter_id}'>${row.submitter_name}</a>`;
    }
  },
  {
    title: 'Referrer',
    data: 'referrer_id',
    render: (row) => {
      return `<a class='email' target='_blank' href='/admin/users/${row.referrer_id}'>${row.referrer_name}</a>`;
    }
  }
];

const Index = () => {
  return (
    <DynamicJqueryTable
      columnsConfig={columnsConfig}
      path="/admin/referrals"
      tableId="referrals-table"
      tableTitle="Referral Tracking"
      noDatepicker={true}
    />
  )
};

export default Index;