import React, { useEffect } from 'react';
import {Helmet} from 'react-helmet';

const FreePublication = ({setFooterText}) => {

  useEffect(() => {
    setFooterText(<p>Sign up for Cureus today and leverage our platform for affordable, accessible medical research publishing.</p>)
   }, [])

  return (
    <React.Fragment>
      <Helmet>
        <title>Free Publishing | Medical Research Journal Articles | Cureus</title>
        <meta name="description" content="Cureus offers the opportunity for free publication in an Open Access medical journal. Share your discoveries with the world. Connect with Cureus today." />
      </Helmet>
      <h1>Qualifying for Free Publication</h1>
      
      <h2>Original Articles, Case Reports, Technical Reports and Editorials</h2>
      <p>All non-<a href='/channels'>channel</a> and non-<a href='/competitions'>competition</a> original article, case report, technical report and editorial submissions undergo a preliminary review to determine if the article meets all Cureus editorial requirements (as listed in this <a href='author_guide#!/overview/introduction'>Author Guide</a>) and qualifies for free publication in our medical journal. All non-channel and non-competition review articles require the purchase of our 
      <a href='/cureus_editing_services'> Preferred Editing</a> service.</p>
      <p>To remain eligible for free publication, research articles cannot contain more than the following number of authors or references: </p>
      <ul className='indented-for-medium-up disc'>
        <li>Original articles: 10 authors and 30 references</li>
        <li>Case reports and technical reports: 5 authors and 20 references</li>
        <li>Editorials: 5 authors and 5 references</li>
      </ul>
      <p>Exceeding either total will disqualify the article from free publication in our medical journal, regardless of how many errors are found during our preliminary review.</p>

      <h2>All Review Articles Require Preferred Editing</h2>
      <p>All non-channel/competition review articles submitted to Cureus will be required to purchase our Preferred Editing service in order to proceed with 
      <a href='/author_guide#!/policies-and-procedures/single-blinded-peer-review'> peer review</a> and publication. This is due to the considerable editorial expenses associated with processing and editing these articles, which typically contain more content and references than other articles.</p>
      <h2>Editor Check: Preliminary Editorial Review</h2>
      <p>For the best chance of publishing your medical journal article for free, consider the areas of focus in our preliminary review:</p>
      <ul className='indented-for-medium-up'>
        <li className='check'>
          <strong>Is this article well-drafted and scientifically credible? </strong>
          Please refer to the <a href='/author_guide#!/overview/accepted-article-types'>Accepted Article Types</a> section.
        </li>
        <li className='check'>
          <strong>Does this article fit within the journal’s scope? </strong>
          Please refer to the <a href='author_guide#!/overview/unaccepted-article-types'>Topics Not Currently Accepted</a> section.
        </li>
        <li className='check'>
          <strong>Are there errors in the following areas? </strong>
        </li>
        <ul className='disc pad-left-14'>
          <li>Reference formatting and accuracy</li>
          <li>Media quality, formatting, labels and placement</li>
          <li>Spelling, grammar, syntax and punctuation errors</li>
          <li>Author names and affiliations</li>
          <li>Text and article formatting</li>
          <li>Proper use of acronyms and initialisms</li>
        </ul>
      </ul>
      <p>Only well-drafted, relevant, credible submissions with few or no errors will be accepted for free publication in our <a href='/'>medical journal</a>. Articles with too many errors will be required to purchase our Preferred Editing service
      (<a href='/cureus_editing_services'>use our calculator to estimate potential editing fees</a>), and articles with severe language issues will require professional language editing by a third party in order to be considered for peer review and free publication in Cureus. DIY editing services such as Grammarly will not be accepted.</p>
      <p>If a research article is not eligible for free publication but is still eligible for publication pending editing, the costs associated are still likely to be much lower than those of other large medical journals thanks to our dedication to significantly reducing barriers to publishing. <a href='/registrations/sign_up'>Submit your research today</a>.</p>
    </React.Fragment>
  )
}

export default FreePublication;