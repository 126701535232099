import { useState, useEffect } from 'react';
import React from 'react';
import { HashRouter as Router, Redirect, Switch, Route, Link } from "react-router-dom";
import OpenTable from './OpenTable';
import ClosedTable from './ClosedTable';

const Main = ({indexPath, updateTablePath}) => {

  const [activeTab, setActiveTab] = useState(window.location.hash.replace(/^#!\/?|\/$/g, ''))

  useEffect(() => {
    setActiveTab(window.location.hash.replace(/^#!\/?|\/$/g, ''))
  }, [activeTab])

  const link = (tab, headerName) => {
    return (
      <Link to={tab}>
        <li className={"tab-title " + (activeTab === tab ? 'active' : '')} onClick={() => setActiveTab(tab)}>
          <a className="dashboard">{headerName}</a>
        </li>
      </Link>
    )
  }

  return (
    <Router hashType={"hashbang"}>
      <h1>Article Message Board Queue</h1>
      <div className="row">
        <div className="large-12 columns cureus-tabs-container">
          <div className="cureus-tabs secondary-tabs dashboard homepage editor-messages-table">
            <ul className="tabs">
            {link('open', 'OPEN')}
            {link('closed', 'CLOSED')}
            </ul>
          </div>
        </div>
          <Switch>
          <Redirect exact from="/" to="/open" />
            <Route path='/open'>
              <OpenTable indexPath = {indexPath} updateTablePath = {updateTablePath} status={activeTab}/>
            </Route>
            <Route path='/closed'>
              <ClosedTable indexPath = {indexPath} updateTablePath = {updateTablePath} status={activeTab}/>
            </Route>
          </Switch>
      </div>
    </Router>
  )
}


export default Main;