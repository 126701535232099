import React, { useEffect } from 'react';
import Modal from '../Modal';
import DeskRejectedModal from '../DeskRejectedModal';

const DeskSubmissions = ( { datasets, startDate, endDate } ) => {

  useEffect(() => {
    populateTable()
  }, [datasets])

  function populateTable() {
    var dt = $("#desk-submissions-table").DataTable({
      destroy: true,
      dom: '<"row"<"large-4 columns"l><"large-6 columns"f>>t<"row"<"large-8 columns"i><"pull-right"p>>',
      "rowCallback": function(row, data, index) {
        $(row.children[2].children[0].children[0]).off().on('click', function() {
          openModal(datasets[index], 'deskRejected')
        })

        $(row.children[3].children[0].children[0]).off().on('click', function() {
          openModal(datasets[index], 'blocked')
        })
      }
    });
    dt.api().clear();
    for (var i = 0; i < datasets.length; i++) {
      var editor = datasets[i];
      dt.fnAddData([
          `<a href='/admin/users/${editor.id}' target='_blank'>${editor.full_name}</a>`,
          editor.approved,
          `<a target='_blank'><div>${editor.desk}</div></a>`,
          `<a target='_blank'><div>${editor.blocked}</div></a>`,
          (((editor.desk + editor.blocked) / editor.approved) * 100).toFixed(0) + '%'
      ], false)
    }
    dt.fnDraw()
  }

  const openModal = (data, type) => {
    Modal.open({
      children: <DeskRejectedModal
        startDate={startDate}
        endDate={endDate}
        data={data}
        type={type}
      />
    })
  }

  return (
    <table className="table-wrapper data-table" id="desk-submissions-table">
      <thead>
        <tr>
          <th>Editor</th>
          <th>Approved</th>
          <th>Desk Rejected</th>
          <th>Blocked</th>
          <th>Percentage</th>
        </tr>
      </thead>
      <tbody></tbody>
    </table>
  )
}

export default DeskSubmissions;