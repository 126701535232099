import React from 'react';
import Helmet from 'react-helmet';

const IcmjeRequirements = () => {
  return (
    <React.Fragment>
      <Helmet>
        <title>ICMJE Authorship Requirements | Author Guide | Cureus</title>
        <meta name='description' content='Learn about the ICMJE authorship requirements at Cureus. Discover ICMJE’s requirements for successful publication and submit your research to Cureus today.' />
      </Helmet>
      <h1>ICMJE Authorship Requirements</h1>
      <p><strong>Cureus follows the <a href='https://www.icmje.org/' target='_blank'>Recommendations for the Conduct, Reporting, Editing, and Publication of Scholarly Work in Medical Journals</a> issued by the International Committee of Medical Journal Editors (ICMJE). In addition, Cureus adheres to the ICMJE uniform requirements of authorship. All authors of an article must meet the following authorship requirements:</strong></p>
      <div className='indented-for-medium-up'>
        <p className='left-gray-bar'>
          <em>&ldquo;Authorship credit should be based on 1) substantial contributions to conception and design, acquisition of data, or analysis and interpretation of data; 2) drafting the article or reviewing it critically for important intellectual content; 3) final approval of the version to be published; AND 4) agreement to be accountable for all aspects of the work in ensuring that questions related to the accuracy or integrity of any part of the work are appropriately investigated and resolved. All those designated as authors should meet all four criteria.</em>
        </p>
        <p className='left-gray-bar'>
          <em>Acquisition of funding, collection of data, or general supervision of the research group alone does not constitute ICMJE authorship.</em>
        </p>
        <p className='left-gray-bar'>
          <em>All persons designated as authors should qualify for authorship, and all those who qualify should be listed.</em>
        </p>
        <p className='left-gray-bar'>
          <em>Each author should have participated sufficiently in the work to take public responsibility for appropriate portions of the content.&rdquo;</em>
        </p>
      </div>
      <h2>Author Contribution Guidelines</h2>
      <p>The <a href='/author_guide#!/author-instructions/author-roles'>submitting author</a> will be prompted during the <a href='/author_guide#!/author-instructions/submitting-an-article'>submission process</a> to select contributions for each listed author per the authorship requirements. Any contributor who does not meet the criteria for authorship may be listed in the Acknowledgements section with their consent.</p>
      <p>Examples include a person who provided technical help or writing assistance or a department chair who provided general support. Disputes regarding authorship and contributorship must be resolved by the authors, as it is not the journal’s responsibility to adjudicate such disputes.</p>
      <p>The submitting author and corresponding author are two distinct designations that may be assigned to one or separate authors.</p>
      <h3>Submitting Author Responsibilities</h3>
      <p>The submitting author's role and responsibilities are to:</p>
      <ul className='indented-for-medium-up'>
        <li className='check'>
          Inform all co-authors when an article bearing their name has been submitted to Cureus. (Upon submission, each co-author will also receive an email requesting confirmation of authorship).
        </li>
        <li className='check'>
          Manage correspondence between Cureus and all co-authors, keeping the full co-author group apprised of the article’s progress. (Each co-author will receive automated email notifications confirming authorship, <a href='/about_publishing'>article publication</a>, and, if necessary, <a href='/author_guide#!/policies-and-procedures/retraction-policy'>article retraction</a>.)
        </li>
        <li className='check'>
          Declare all relevant <a href='/author_guide#!/policies-and-procedures/conflicts-of-interest'>conflicts of interest</a> on behalf of all co-authors.
        </li>
        <li className='check'>
          Ensure, to the best of their abilities, that the article fully adheres to all Cureus policies (including such items as publication ethics, data deposition, materials deposition, etc.).
        </li>
      </ul>
      <h3>Corresponding Author Responsibilities</h3>
      <p>The corresponding author’s role and responsibilities are to:</p>
      <ul className='indented-for-medium-up'>
        <li className='check'>
          Respond to all queries pertaining to the published article while providing relevant data and materials as requested.
        </li>
      </ul>
    </React.Fragment>
  )
}

export default IcmjeRequirements;