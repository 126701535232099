import React, {useState, useEffect} from 'react';
import Modal from '../Modal';

const AddNoteModal = ({editorNotes, setEditorNotes, initialEditorId=null, fetchData, from, to, selectedRange}) => {

  const [selectedEditorId, setSelectedEditorId] = useState(initialEditorId || 0);
  const [noteContent, setNoteContent] = useState('');

  const selectedEditor = editorNotes.find((editor) => editor.id == selectedEditorId);

  useEffect(() => {
    if (selectedEditor) {
      setNoteContent(selectedEditor.note || '');
    } else {
      setNoteContent('');
    }
  }, [selectedEditorId]);

  const handleEditorChange = (e) => {
    setSelectedEditorId(e.target.value);
  };

  const handleNoteChange = (e) => {
    setNoteContent(e.target.value);
  };

  const createOrUpdateNote = () => {
    $.ajax({
      type: renderRequestType(),
      url: renderUrl(),
      dataType: "JSON",
      data: {editorId: selectedEditorId, note: noteContent, formatted_tooltip_note: formatNoteContent(noteContent)},
      success: function(res) {
        setEditorNotes(res.data);
        fetchData(from, to, selectedRange);
        Modal.close();
      }
    })
  }

  const renderUrl = () => {
    return selectedEditor.note === null ? "/admin/approval_editor_notes" : `/admin/approval_editor_notes/${selectedEditor.note_id}`;
  }

  const renderRequestType = () => {
    return selectedEditor.note === null ? "POST" : "PUT";
  }

  const formatNoteContent = (note) => {
    const paragraphs = note.split(/\n\s*\n/);
    const formattedParagraphs = paragraphs.map(paragraph => {
      const withBreaks = paragraph.replace(/\n/g, '<br>');
      return `<p>${withBreaks}</p>`;
    });
    return formattedParagraphs.join('');
  };

  return (
    <div id={"add-note-reveal-modal"} className="reveal-modal middling" data-reveal>
      <div className="modal-body">
        <div className="modal-close">
          <a href='#' className='cancel-modal'>
            <img src="https://public.cureus.com/close_x.png"/>
          </a>
        </div>

        <div className="modal-content">

          <div className="row">
            <div className="large-12 columns">
              <h5>Notes</h5>
            </div>
          </div>

          <div className="row">
            <div className="large-12 columns">
            <select
              id="editor-select"
              value={selectedEditorId}
              onChange={handleEditorChange}
            >
              <option value="">-- Select Editor --</option>
              {editorNotes.map((editor) => (
                <option key={editor.id} value={editor.id}>
                  {editor.full_name}
                </option>
              ))}
            </select>
            </div>
          </div>

          <div className="row">
            <div className="large-12 columns">
              <textarea 
                className='editor-note'
                placeholder="No notes added yet."
                style={{ height: '300px' }}
                value={noteContent}
                onChange={handleNoteChange}/>
              <a className="button" onClick={createOrUpdateNote}>Save</a>
            </div>
          </div>

        </div>
      </div>
    </div>
  );
};

export default AddNoteModal;