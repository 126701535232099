import React from 'react';
import Modal from '../Modal';
import EditTermModal from './EditTermModal';

const BlackListTermCell = ({ term, fieldName, blackListFields, handleCreate}) => {
  const openEditTermModal = (e) => {
    e.preventDefault()
    Modal.open({
      children: <EditTermModal initialTerm={term} fieldName={fieldName} blackListFields={blackListFields} handleCreate={handleCreate}/>
    });
  }

  return (
    <a onClick={openEditTermModal}>{term.text} </a>
  );
};

export default BlackListTermCell;