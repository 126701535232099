import React, { useState, useEffect } from 'react';
import { HashRouter as Router, Switch, Route, Link } from "react-router-dom";
import Table from "./Table"
import Modal from '../Modal';
import NewTermModal from "./NewTermModals";
import UploadTermsModal from "./UploadTermsModal";
import { Cureus } from '../../Cureus';
import Tab from '../Shared/Tabs';

const Main = ({initialBlackListedFields}) => {
  const [selectedBoxes, setSelectedBoxes] = useState([])
  const [blackListTerms, setBlackListTerms] = useState([])
  const [blackListFields, setBlackListFields] = useState(initialBlackListedFields)
  const [isLoading, setIsLoading] = useState(true)
  const [currentTab, setCurrentTab] = useState("")

  useEffect(() => {
    fetchBlackListTerms()
  }, [currentTab])

  const fetchBlackListTerms = () => {
    if (currentTab === "") { return }
    $.ajax({
      type: 'GET',
      url: "/admin/black_list_terms",
      data: { field: currentTab },
      success: function(data) {
        setBlackListTerms(data)
      }
    })
  }

  const handleDelete = (e) => {
    if(window.confirm('Are you sure to delete this record?')){
      $.ajax({
        type: 'DELETE',
        url: "/admin/black_list_terms/destroy_all",
        data: {ids: selectedBoxes, field: currentTab},
        success: () => {
          fetchBlackListTerms()
          setSelectedBoxes([])
        }
      });
    };
  }

  const handleCancel = () => {
    setSelectedBoxes([])
    unCheckAllBoxesAndClearState()
  }

  const actionButtons = () => {
    return (
      <>
        <a className="button alert" onClick={handleDelete} >Delete</a>
        <a className="button secondary" onClick={handleCancel}>Cancel</a>
      </>
    )
  }

  const termsTable = (tabName) => {
    return <Table
      allCheckBoxes={allCheckBoxes}
      blackListFields={blackListFields}
      blackListTerms={blackListTerms}
      fetchBlackListTerms={fetchBlackListTerms}
      fieldName={tabName}
      handleCreate={handleCreate}
      isLoading={isLoading}
      selectAllCheckboxElement={selectAllCheckboxElement}
      selectedBoxes={selectedBoxes}
      setBlackListFields={setBlackListFields}
      setCurrentTab={setCurrentTab}
      setIsLoading={setIsLoading}
      setSelectedBoxes={setSelectedBoxes}
      unCheckAllBoxesAndClearState={unCheckAllBoxesAndClearState}
    />
  }

  const unCheckAllBoxesAndClearState = () => {
    allCheckBoxes().forEach( (box) => { box.checked = false })
    selectAllCheckboxElement().checked = false
    setSelectedBoxes([])
  }

  const allCheckBoxes = () => {
    return document.querySelectorAll(".checkbox-term")
  }

  const selectAllCheckboxElement = () => {
    return document.querySelector("#select-all")
  }

  const handleCreate = (term, selectedFields=[], postOrPatch='POST') => {
    let urlLink = "/admin/black_list_terms"
    if (postOrPatch === 'PATCH') { urlLink = `/admin/black_list_terms/${term}`}
    
    $.ajax({
      type: postOrPatch,
      url: urlLink,
      data: {term: term, selectedFields: selectedFields},
      success: () => {
        // can probably return the list terms here instead of calling
        fetchBlackListTerms()
        Modal.close()
      },
      error: (json) => {
        Cureus.Flash.warning(json.responseJSON.message)
      }
    });
  }  

  const openAddTermModal = (e) => {
    e.preventDefault()
    Modal.open({
      children: <NewTermModal blackListFields={blackListFields} setBlackListFields={setBlackListFields} handleCreate={handleCreate}/>
    });
  }

  const handleUploadCSV = (fileData, selectedFields) => {
    let formData = new FormData()
    formData.append("file", fileData);

    returnSelectedFields(selectedFields).forEach(field => {
      formData.append("selectedFields[]", field);
    });

    setIsLoading(true)

    fetch('/admin/black_list_terms/upload_terms', {
      method: 'POST',
      body: formData,
      credentials: "same-origin",
      headers: {
        "X-CSRF-Token": $("[name='csrf-token']").attr("content"),
      },
    })
    .then((res) => {
      setIsLoading(false)
      if (res.ok) {
        return res.json()
      } else {
        return Promise.reject(res.json())
      }
    })
    .then((json) => {
      if (json.duplicateCount > 0) {
        Cureus.Flash.warning(`Duplicate terms detected:${JSON.stringify(json.duplicateTerms)}, total duplicate count ${json.duplicateCount}, total terms in csv : ${json.termsCount}`)
      }
      fetchBlackListTerms()
      Modal.close()
    })
    .catch((failedPromise) => {
      failedPromise.then((json) => {
        Cureus.Flash.warning(json.message)
      })
    })
  }

  const openUploadTermsModal = (e) => {
    e.preventDefault()
    Modal.open({
      children: <UploadTermsModal blackListFields={blackListFields} handleUploadCSV={handleUploadCSV}/>
    });
  }

  const returnSelectedFields = (selectedFields) => {
    return selectedFields.filter(field => field.checked).map(field => field.name)
  }

  const tabs = [
    { path: '/names', label: 'FIRST/LAST NAMES', component: termsTable("names") },
    { path: '/subspecialty', label: 'SUBSPECIALTY', component: termsTable("subspecialty") },
    { path: '/institution', label: 'ACADEMIC INSTITUTION', component: termsTable("institution") },
    { path: '/department', label: 'DEPARTMENT/SPECIALIZATION', component: termsTable("department") },
    { path: '/city', label: 'CITY', component: termsTable("city") },
    { path: '/bio', label: 'ABOUT', component: termsTable("bio") }
  ];

  return (
    <>
      <h2>Blacklisted</h2>
      <div className="row">
        <div className="small-6 columns">
          <a className="button" onClick={openAddTermModal}>
            <i className="fas fa-plus fa-sm"/>&nbsp;&nbsp;Add Term
          </a>
          <a className="button small stencil-blue" onClick={openUploadTermsModal}>
            <img src="https://public.cureus.com/upload-3x.png"/>
            &nbsp;&nbsp;
            Upload List
          </a>
          {selectedBoxes.length > 0 && actionButtons()}
        </div>
      </div>

      {isLoading &&
        <div className='spinner-container'>
        <img src='https://public.cureus.com/ajax-loader.gif'/>
      </div>}

      <Router hashType={"hashbang"}>
        <Tab tabs={tabs} />
      </Router>


    </>
  )
}

export default Main;