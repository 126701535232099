
import React, { useEffect, useState } from 'react'
import {Cureus} from '../../../Cureus';
import MessageStatusModal from './MessageStatusModal';
import Modal from '../../Modal';

const EditorMessagesTable = ({indexPath, updateTablePath, status}) => {

  const [messagesToRemove, setMessagesToRemove] = useState([]);
  const [showButton, setShowButton] = useState(false);
  const dropdownOptions = [{id: 0, value: 'none', text: 'None', color: "grey"}, {id: 1, value: 'in_progress', text: 'In Progress', color: "orange"}, {id: 2, value: 'closed', text: 'Closed', color: "red"}];
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    fetchData(status);
  }, []);

  const fetchData = (status) => {
    setIsLoading(true)
    $.ajax({
      type: 'GET',
      dataType: 'json',
      data: {status: status},
      url: indexPath,
      success: function(data) {
        populateTable(data.data);
        setIsLoading(false);
      }
    });
  }

  const createMessageStatus = (articleId, messageId, newStatus, previousStatus) => {
    $.ajax({
      type: 'POST',
      dataType: 'json',
      url: `/admin/articles/${articleId}/messages/${messageId}/create_message_status`,
      data: {newStatus: newStatus},
      success: function(data) {

        const rowElement = $(`#status-selector-${messageId}-${articleId}-${previousStatus}`).closest('tr'); 

        updateTableRow(rowElement, data.message.data.attributes, articleId, messageId);

        Cureus.Flash.success("Messages successfully updated");
      }
    });
  }

  const shouldRemoveRow = (currentTableStatus, rowMessageState) => {
    if (currentTableStatus === 'closed') {
      return rowMessageState !== 'closed';
    }
    return rowMessageState === 'closed';
  };

  const updateTableRow = (rowElement, rowData, articleId, messageId) => {
    const table = $('#editor-messages-table').DataTable();
    const row = table.api().row(rowElement);

    if (row.length) { 
      let newData = row.data();
      newData[9] = renderStatusUpdatedAt(rowData.updated_at, articleId, messageId);
      newData[8] = renderDropdown(articleId, messageId, rowData.state);

      if (shouldRemoveRow(status, rowData.state)) {
        row.remove().draw('page');
        return;
      }
      row.data(newData).invalidate().draw('page'); 
    } else {
        Cureus.Flash.alert("Message could not be updated");
    }
  }

  const updateMessageTable = (messagesToRemove) => {
    $.ajax({
      type: 'PUT',
      dataType: 'json',
      url: updateTablePath,
      data: {message_ids: messagesToRemove, status: status},
      success: function(data) {
        populateTable(data.data);
        Cureus.Flash.success("Messages successfully updated");
        setShowButton(false);
      }
    });
  }

  const setColumns = () => {
    return [
      {width: '160px', class: 'centered'},
      {width: '160px', class: 'centered'},
      {width: '160px', class: 'centered'},
      {width: '160px', class: 'centered'},
      {width: '160px', class: 'centered'},
      {width: '160px', class: 'centered'},
      {width: '320px'},
      {width: '320px'},
      {width: '320px', class: 'centered'},
      {width: '210px'},
      {width: '0px', class: 'centered'},
      {width: '320px', class: 'centered'}
    ]
  }

  const parseUser = (userFullName, userId) => {
    return `<a target='_blank' href='/admin/users/${userId}'>${userFullName}</a>`
  }

  const parseResponsesNeeded = (responseList) => {
    let ListOfResponses = responseList.map((response) => {
      return `<li>${response}</li>`
    })
    return `<ul>${ListOfResponses.join('')}</ul>`
  }

  const parseArticleLink = (article_id, article_title, showTitle=false) => {
    return `<a target='_blank' href='/admin/articles/${article_id}'>${showTitle ? article_title : article_id}</a>`;
  }

  const renderCheckbox = (message_id) => {
    return `<input id="${message_id}_id" className="checkbox" type="checkbox" name="checkbox"/>`;
  }

  const renderDropdown = (articleId, rowId, lastStatus) => {
    return `<div></div><div><select class='status-selector' id='status-selector-${rowId}-${articleId}-${lastStatus}'></select></div><span class="hidden-sort-value" style="display: none;">${lastStatus}</span></div>`
  }

  const renderStatusUpdatedAt = (statusUpdatedAt, articleId, messageId, articleTitle=null) => {
    return `<a data-article-id="${articleId}" data-message-id="${messageId}" data-article-title="${articleTitle}"><p class="update-sort">${statusUpdatedAt}</p></a>`
  }

  const handleDelete = () => {
    let messagesToRemove = [];
    window.document.querySelectorAll('input[type="checkbox"][name=checkbox]:checked').forEach((checkbox) => {
      messagesToRemove.push(checkbox.getAttribute("id").split("_")[0])
    })
    setMessagesToRemove(messagesToRemove);
    updateMessageTable(messagesToRemove);
  }

  const populateDropdown = (dropdown) => {
    dropdown.innerHTML = '';
    dropdownOptions.forEach((option) => {
      const optionElement = document.createElement('option');
      optionElement.value = option.value;
      optionElement.text = option.text;
      dropdown.appendChild(optionElement);
    });
  };

  const openStatusHistoryModal = (articleId, messageId, articleTitle) => {
    Modal.open({
      children: <MessageStatusModal
          articleId={articleId}
          messageId={messageId}
          articleTitle={articleTitle}
        />
    });
  }
  
  const setDropdownDefault = (dropdown, lastStatus) => {
    dropdown.value = lastStatus;
    dropdown.style.color = dropdownOptions.find((option) => option.value === lastStatus).color;
  };
  
  const handleDropdownChange = (dropdown) => {
    dropdown.removeEventListener('change', function () {});
    dropdown.addEventListener('change', function () {
      const selectedStatus = dropdown.value;
      const [ , , messageId, articleId, lastStatus ] = dropdown.id.split('-');
      dropdown.style.color = dropdownOptions.find((option) => option.value === selectedStatus).color;
      createMessageStatus(articleId, messageId, selectedStatus, lastStatus);
    });
  };

  const populateTable = (data) => {
    let dt = $('#editor-messages-table').DataTable({
        dom: '<"row"<"large-9 columns"f>><"table"t>r<"row bottom"<"large-4 columns page-nums"p><"large-2 columns end show-amount"l>>',
        destroy: true,
        columns: setColumns(),
        columnDefs: [
          {targets: [10], orderData: [9]},
          {targets: [10], visible: false},
          {targets: [9], orderData: [10]},
          {targets: [11], orderable: false}
        ],
        searching: true,
        pageLength: 20, 
        rowCallback: async function(row) {
          row.addEventListener('click', function() {
            if (document.querySelectorAll('input[type="checkbox"][name=checkbox]:checked').length == 0) {
              setShowButton(false);
            } else {
              setShowButton(true);
            }
          });

          let historyElement = row.children[9].children[0]
          historyElement.addEventListener('click',function() {
            openStatusHistoryModal(historyElement.dataset.articleId, historyElement.dataset.messageId, historyElement.dataset.articleTitle)
          })
          const dropdown = row.querySelector('.status-selector');
          if (dropdown) {
            const lastStatus = dropdown.id.split('-').pop();
            populateDropdown(dropdown);
            setDropdownDefault(dropdown, lastStatus); 
            handleDropdownChange(dropdown);
          }
        }
    });

    dt.api().clear();
    for (var i = 0; i < data.length; i++) {
      let row = data[i];
      dt.fnAddData( [
        renderCheckbox(row.message_id),
        row.created_at,
        parseUser(row.author_name, row.author_id),
        parseArticleLink(row.article_id, row.title),
        row.type,
        row.tier_badge,
        parseArticleLink(row.article_id, row.title, true),
        parseResponsesNeeded(row.response_array),
        renderDropdown(row.article_id, row.message_id, row.message_state),
        renderStatusUpdatedAt(row.last_updated, row.article_id, row.message_id, row.title),
        row.last_updated_time,
        row.message
      ], false)
    }
    dt.fnDraw();
  }

  const renderDataTable = () => {
    return (
      <table id="editor-messages-table" className='table-wrapper data-table' style={{minWidth: '1300px'}}>
        {isLoading &&
          <div className='spinner-container' style={{background: 'rgba(255,255,255,.5)', top: '-100px'}}>
          <img src='https://public.cureus.com/ajax-loader.gif'/>
        </div>}
        <thead>
          <tr>
            <th className="single-action-icon"><i className="far fa-trash-alt"/></th>
            <th className='centered'>Date</th>
            <th className='centered'>Posted By</th>
            <th className='centered'>Article ID</th>
            <th className='centered'>Type</th>
            <th className='centered'>Tier</th>
            <th className='centered'>Title</th>
            <th className='centered'>Tagged</th>
            <th className='centered'>Status</th>
            <th className='centered'>Last Updated/Status History</th>
            <th className='centered'>Time</th>
            <th className='centered'>Message</th>
          </tr>
        </thead>
        <tbody></tbody>
      </table>
    )
  }

  return (
    <div className='main'>
      <a style={{display: showButton ? 'inline-block' : 'none'}} className="button alert" onClick={() => handleDelete()}>Delete</a>
      {renderDataTable()}
    </div>
  )
}

export default EditorMessagesTable