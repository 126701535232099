import React, {useState, useEffect} from 'react';
import DynamicJqueryTable from '../Shared/DynamicJqueryTable';
import { addTextColor } from './UtilityFunctions';
import Modal from '../Modal';
import AeHistoryModal from './AeHistoryModal';
import AddNoteModal from './AddNoteModal';

const ActivityLog = ({userEmail, checkboxObj}) => {

  const [editorNotes, setEditorNotes] = useState([]);
  const [dateData, setDateData] = useState({});

  useEffect(() => {
    fetchApprovalEditorNotes();
    
    $(document).on('click', '.email-history-date', handleHistoryClick);
    return () => {
      $(document).off('click', '.email-history-date', handleHistoryClick);
    };
  }, []);

  useEffect(() => {
    $(document).on('click', '.note-icon', function () {
      const editorId = $(this).data('editor-id');
      openNoteModal(editorId);
    });
    return () => {
      $(document).off('click', '.note-icon');
    };
  }, [editorNotes, dateData]);

  const openNoteModal = (editorId) => {
    Modal.open({
      children: (
        <AddNoteModal
          editorNotes={editorNotes}
          setEditorNotes={setEditorNotes}
          initialEditorId={editorId}
          fetchData={dateData.fetchData}
          from={dateData.from}
          to={dateData.to}
          selectedRange={dateData.selectedRange}
        />
      ),
    });
  };

  const generalActivityColumns = [
    checkboxObj,
    { 
      title: 'Editor',
      data: 'full_name',
      width: '180px',
      orderable: true,
      orderData: [8],
      render: (row) => {
        if (row.tooltip_content) {
          return `
            <div class='name-container'>
              <a class='column-hoverable' id=${row.id} target='_blank' href='/admin/users/${row.editor_id}'>${row.full_name}</a>
              <i class="far fa-sticky-note note-icon tip-top" data-tooltip=""  title="${row.tooltip_content}" data-editor-id="${row.editor_id}" ></i>
            </div>
          `;
        } else {
          return `
            <a class='column-hoverable' id=${row.id} target='_blank' href='/admin/users/${row.editor_id}'>${row.full_name}</a>
          `;
        }
      }
    },
    { title: 'Contribution volume', data: 'contribution_volume', class: 'centered', tooltip: 'Approved + Rejected' },
    { title: 'Turnaround time (days)', data: 'turnaround_time', class: 'centered', tooltip: 'Avg elapsed time from claim to completion Target: 0-24 hrs Close enough: 25-72 hrs Problematic: >72 hrs' },
    { title: 'Accepted', data: 'accepted', class: 'centered' },
    { title: 'Rejected', data: 'rejected', class: 'centered' },
    { title: 'Revisions required', data: 'deferred', class: 'centered' },
    { title: 'Requested Re-review', data: 're_review', class: 'centered' },
    {
      title: 'sort_column',
      data: 'full_name',
      visible: false,
      orderable: false
    },
    { 
      title: 'Email History',
      data: 'last_reminder_date',
      class: 'centered',
      width: '140px',
      render: (row) => {
        if (!row.last_reminder_date) {
          return '';
        }
        
        return `
          <a 
            class='column-hoverable email-history-date'
            id=${row.id}
            data-editor-id="${row.editor_id}"
            data-editor-name="${row.full_name}"
            data-date="${row.last_reminder_date}"

          >
            ${row.last_reminder_date}
          </a>
        `;
      }
    },
    {
      title: 'history_date',
      data: 'sort_last_reminder_date',
      visible: false,
      orderable: false
    }
  ];

  const otherActivityColumns = [
    { 
      title: 'Editor',
      data: 'full_name',
      width: '140px',
      orderable: true,
      render: (row) => {
        return `<a class='column-hoverable' id=${row.id} target='_blank' href='/admin/users/${row.editor_id}'>${row.full_name}</a>` 
      }
    },
    { title: 'Peer Review Rejected', data: 'peer_review_rejected', class: 'centered' },
    { title: '# Msg Board Comments', data: 'msg_board_comments', class: 'centered' },
    { title: 'Email to Author', data: 'email_to_author', class: 'centered' },
    { title: 'Total view versions clicked', data: 'total_view_versions_clicked', class: 'centered' },
    { title: '% Articles previewed', data: 'articles_viewed_percent', class: 'centered', tooltip: "% of articles previewed out of total article claims" }
  ];

  const matchedUnmatchedColumns = [
    { 
      title: 'Editor',
      data: 'full_name',
      width: '140px',
      orderable: true,
      render: (row) => {
        return `<a class='column-hoverable' id=${row.id} target='_blank' href='/admin/users/${row.editor_id}'>${row.full_name}</a>` 
      }
    },
    { title: 'Completed matches', data: 'completed_matches', class: 'centered' },
    { title: 'Completed unmatches', data: 'completed_unmatches', class: 'centered' },
    { title: 'Completed zero matches', data: 'completed_zero_matches', class: 'centered', tooltip: "Article did not match with any AE" },
    { title: '% Matched completions', data: 'match_completion_ratio', class: 'centered', tooltip: "% of matched articles out of total article completions" },
    { title: '% Unmatched completions', data: 'unmatched_completion_ratio', class: 'centered', tooltip: "% of unmatched articles out of total article completions" }
  ];

  const rowRenderCallback = (row, data) => {
    colorizeTurnAroundTime(data, row);
  }

  const colorizeTurnAroundTime = (data, row) => {
    let cssClass;
    let hours = parseInt(data[3].split(' ')[0]);
    if (hours >= 24 && hours <= 72) {
      cssClass = "orange-text";
    } else if (hours > 72 ) {
      cssClass = "red-text";
    }

    if (cssClass) {
      addTextColor(row, cssClass, 3);
    }
  }

  const fetchApprovalEditorNotes = () => {
    $.ajax({
      type: "GET",
      url: "/admin/approval_editor_notes",
      dataType: "JSON",
      success: function(res) {
        setEditorNotes(res.data);
      }
    })
  }

  const renderTooltip = () => {
    $('.note-icon').foundation('reflow');
  }

  const openHistoryModal = (editorId, editorName) => {
    Modal.open({
      children:
        <AeHistoryModal 
          editorId={editorId}
          editorName={editorName}
        />
    })
  }

  const handleHistoryClick = function () {
    const editorId = $(this).data('editor-id');
    const editorName = $(this).data('editor-name');
    openHistoryModal(editorId, editorName);
  };


  return (
    <>
      <DynamicJqueryTable
        columnsConfig={generalActivityColumns}
        path="/admin/ae_dashboards/general_activity"
        tableId="general-activity"
        tableTitle={"General Activity"}
        showCheckBoxes={true}
        rowRenderCallback={rowRenderCallback}
        userEmail={userEmail}
        showCsv={true}
        initialDateIndex={1}
        showAddNote={true}
        editorNotes={editorNotes}
        setEditorNotes={setEditorNotes}
        columnDefsProp={[{ targets: [1], orderData: [8] }, { targets: [8], visible: false }, {targets: [9], orderData: [10]}, {targets: [10], visible: false}]}
        showToolTip={true}
        toolTipProp={renderTooltip}
        setDateData={setDateData}
        modalProp={openNoteModal}
      />
      <DynamicJqueryTable
        columnsConfig={matchedUnmatchedColumns}
        path='/admin/ae_dashboards/matched_unmatched'
        tableId="matched-unmatched"
        tableTitle={"Percentage of matched vs unmatched articles"}
        userEmail={userEmail}
        showCsv={true}
        initialDateIndex={4}
      />
      <DynamicJqueryTable
        columnsConfig={otherActivityColumns}
        path='/admin/ae_dashboards/other_activity'
        tableId="other-activity"
        tableTitle={"Other Activity"}
        userEmail={userEmail}
        showCsv={true}
        initialDateIndex={4}
      />
    </>
  )
};

export default ActivityLog;