import React from 'react';

const StepByStepInstructions = () => {

  return (
    <React.Fragment>
      <h1>Step-by-Step Instructions</h1>

      <h2>Creating a Collection</h2>
      <p>You will be notified via email whether your collection proposal has been accepted or not. If your proposal is accepted, you will have access to a new tab on your Cureus dashboard labeled ‘My Collections’. Click the ‘+’ button in the ‘New Collection’ section to begin building your collection.</p>

      <div className="big-numbers">
        

        <div className="step-1">
          <h3>Title</h3>
          <div className='submission-step-info stack-small'>
            <div className='left-side'>
              <div className='space-bottom-s'>
                <i className='fas fa-check blue'></i>
                <div className='icon-before blue small-font'>
                  <strong>APA style</strong>
                </div>
              </div>
              <div className='space-bottom-s'>
                <i className='fas fa-check blue'></i>
                <div className='icon-before blue small-font'>
                  <strong>Title case</strong>
                </div>
              </div>
              <div>
                <i className='fas fa-check blue' style={{position: 'relative', bottom: '15px'}}></i>
                <div className='icon-before blue small-font'>
                  <strong>Only one <br/> sentence</strong>
                </div>
              </div>
            </div>
            <div className='right-side'>
              <div className="space-bottom-s">
                <div className='icon-with-text'>
                  <div className='icon'>
                    <i className='fas fa-exclamation-triangle red'></i>
                  </div>
                  <div className='text small-font'>
                    <strong>Do not enter the title in all caps or include a period at the end.</strong>
                  </div>
                </div>
              </div>
              <div className="space-bottom-s">
                <div className='icon-with-text'>
                  <div className='icon'>
                    <i className='fas fa-exclamation-triangle red'></i>
                  </div>
                  <div className='text small-font'>
                    <strong>Acronyms, initialisms or abbreviations are not permitted in the article title. Exceptions include common abbreviations such as COVID-19, HIV, MRI, etc.</strong>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>


        <div className="step-2">
          <h3>Banner Image</h3>
          <p>Your collection needs a banner image to draw attention and quickly convey the theme of your collection at a glance. If your image is not original, please make sure you have a license and permission for reuse. We recommend using a free image repository such as <a href="https://unsplash.com/" target="_blank">Unsplash</a> or <a href="https://www.pexels.com/" target="_blank">Pexels</a>.</p>

            <div className='banner-blue-text-container'>
              <div className='icon-before blue small-font'>
                <i className='fas fa-check blue'></i>
                <strong>Accepted File Types</strong>
              </div>
              <div className='text small-font description'>
                <strong>.PNG, .JPEG, .TIFF</strong>
              </div>
            </div>
            <div className='banner-blue-text-container'>
              <div className='icon-before blue small-font'>
                <i className='fas fa-check blue'></i>
                <strong>Minimum Width</strong>
              </div>
              <div className='text small-font description'>
                <strong>900 Pixels</strong>
              </div>
            </div>
            <div className='banner-blue-text-container'>
              <div className='icon-before blue small-font'>
                <i className='fas fa-check blue'></i>
                <strong>Recommended Minimum Width</strong>
              </div>
              <div className='text small-font description'>
                <strong>1600 Pixels</strong>
              </div>
            </div>
            <div className='banner-blue-text-container'>
              <div className='icon-before blue small-font'>
                <i className='fas fa-check blue'></i>
                <strong>Maximum File Size</strong>
              </div>
              <div className='text small-font description'>
                <strong>10 MB</strong>
              </div>
            </div>
        </div>


        <div className="step-3">
          <h3>Categories</h3>
          <p>Select one to three categories to assist readers in finding your collection. Beginning with this step, you may preview your draft at any time to see how the published version will be displayed.</p>
        </div>


        <div className="step-4">
          <h3>Keywords</h3>
          <p>Add at least one keyword (maximum of 10) to further ensure interested authors and readers can find your collection. Please keep your keywords short (a maximum of 3-4 words each) and as specific as possible.</p>
        </div>


        <div className="step-5">
          <h3>Scope</h3>
          <p>This section is where you will enter the main description of your collection. It’s important that you properly capture the theme and scope of your collection as this will inform interested readers as well as authors who might wish to submit to your collection. Provide an overview of the collection and any themes or trends you wish to highlight. You also have the option to include additional background or other information.</p>
        </div>


        <div className="step-6">
          <h3>Topics</h3>
          <p>This is your opportunity to provide authors with suggested topics for inclusion in your collection. The more detail, the better!</p>
        </div>


        <div className="step-7">
          <h3>FAQ</h3>
          <p>Try to anticipate potential questions related to your collection. Are there specific topics that are excluded from the collection? Or nuances related to the topic that require additional explanation? This is the perfect place to address these potential questions.</p>
        </div>


        <div className="step-8">
          <h3>Collection Editors</h3>
          <p>Will your collection have co-editors to assist you in promoting the collection and reviewing articles for potential inclusion? If so, add them here. You’ll need each co-editor’s name, email address, and affiliation information in order to add them. Make sure not to forget anyone as co-editors cannot be added after your collection has been published.</p>
        </div>

        <div className="step-9">
          <h3>Collection Articles</h3>
          <p>Have you or your co-editors published articles in Cureus that should be added to the collection? If so, please add them here. Please note that only articles authored by a guest editor or co-editor may be added.</p>
        </div>

        <div className="step-10">
          <h3>Submission Window</h3>
          <p>Add optional start and end dates for the article submission window here. As a guest editor, you will be able to close the submission window at any time.</p>
        </div>

        <div className="step-11">
          <h3>Summary</h3>
          <p>The summary contains a section-by-section outline of your collection. You can review each aspect of your collection and jump directly to a specific section of the process if you’d like to make changes.</p>
        </div>

        <div className="step-12">
          <h3>Submit</h3>
          <p>Please preview your collection draft one last time and make sure that everything looks good. You cannot submit your collection without first previewing your work. When you’re satisfied, click the ‘Request Publication’ button.</p>
          <p>Your collection will be queued for editorial review and you will be notified via email when review is complete. If revisions are required, you will receive an email with notes from the editor and a request to revise and resubmit the collection. Collections are typically only rejected if they drastically diverge from your previously submitted proposal. If your collection is approved, it will be published immediately.</p>
        </div>
      </div>

      <h2>Managing a Collection</h2>
      <p>
        Now that your Cureus Collection has been published, it’s time to promote your collection and review article submissions.
      </p>
      <p>
        Only the primary guest editor (who created and submitted the collection) can make changes to a published collection. Co-editors are limited to reviewing article submissions for inclusion in the collection. The primary Guest Editor can close the submission window at any time, but please note that once closed, the submission window cannot be reopened.
      </p>
      <p>
        Articles submitted for inclusion in your collection will be visible in the Pending Articles section of the My Collections tab of your dashboard. You’ll also see a bell icon notification next to your profile thumbnail in the top right corner of the screen when an article is ready for your review. Clicking the icon will display a modal with a link to your dashboard so you can determine if the article should be included or excluded from your collection.
      </p>
      <p>
        It’s important to promote your collection with colleagues and other potential authors in order to receive high-quality article submissions on your collection’s topic or theme. As a new guest editor, you'll receive our collection promotion guide with step-by-step instructions on how to attract authors and publicize your collection.
      </p>
    </React.Fragment>
  )
}

export default StepByStepInstructions;