
var createReactClass = require('create-react-class');
import React from 'react';

const ChannelFilter = createReactClass({
  getInitialState: function() {
    return {
      filter: "Channel::AcademicChannel"
    }
  },

  componentWillMount: function() {
    if(this.props.selectedChannel.type) {
      this.setState({
        filter: this.props.selectedChannel.type
      })
    }
  },

  handleFilter: function(filter) {
    var self = this;

    if(self.state.filter == filter) { filter = null; }

    self.setState({filter: filter}, function() {
      self.props.onFilter(filter);
    });
  },

  render: function() {
    return (
      <p>
        <a
          id="academic-departments"
          style={ this.state.filter == 'Channel::AcademicChannel' ? {fontWeight: 'bold'} : {}}
          onClick={ this.handleFilter.bind(this, 'Channel::AcademicChannel') }>
          Academic Departments
        </a>
        &nbsp;
        |
        &nbsp;
        <a
          style={ this.state.filter == 'Channel::ProfessionalSocietyChannel' ? {fontWeight: 'bold'} : {}}
          onClick={ this.handleFilter.bind(this, 'Channel::ProfessionalSocietyChannel') }>
          Professional Societies
        </a>
        &nbsp;
        |
        &nbsp;
        <a
          style={ this.state.filter == 'Channel::PeChannel' ? {fontWeight: 'bold'} : {}}
          onClick={ this.handleFilter.bind(this, 'Channel::PeChannel') }>
          PE Channels
        </a>
        &nbsp;
        |
        &nbsp;
        <a
          style={ this.state.filter == 'Channel::OtherChannel' ? {fontWeight: 'bold'} : {}}
          onClick={ this.handleFilter.bind(this, 'Channel::OtherChannel') }>
          Other
        </a>
      </p>
    )
  }
});

export default ChannelFilter;
