import React, { useState, useEffect } from 'react';
import NewTermModal from './NewTermModals';

const EditTermModal = ({blackListFields, handleCreate, initialTerm}) => {

  return (
    <NewTermModal blackListFields={blackListFields} handleCreate={handleCreate} initialTerm={initialTerm} postOrPatch="PATCH" />
  )
}

export default EditTermModal;