import React, { useState, useEffect, useRef } from 'react';
import ReactDOM from "react-dom";
import BlackListTermCell from './BlackListTermCell';
import moment from 'moment';

const Table = ({
  allCheckBoxes,
  blackListFields,
  blackListTerms,
  fieldName,
  handleCreate,
  selectAllCheckboxElement,
  setCurrentTab,
  setIsLoading,
  setSelectedBoxes,
  unCheckAllBoxesAndClearState
}) => {

  const isFirstRender = useRef(true);

  useEffect(() => {
    setIsLoading(true)
    setCurrentTab(fieldName)
  }, [])

  useEffect(() => {
    // for spinner
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return;
    }
    initializeTable(blackListTerms);
  }, [blackListTerms])

  const setColumns = () => {
    return [
      {width: '5%'},
      {width: '25%', orderData: [3]},
      {
        // when displaying, show date in MM-DD-YYYY format, when sorting it will sort by ISO date
        width: '100px',
        render: (date, type, row) => {
          return (type === 'display') ? date : moment(date, "MMMM-DD-YYYY").toISOString();
        }
      },
      {searchable: true, visible: false},
    ]
  }

  const mapColumns = (dt, termData) => {
    return (
      dt.fnAddData([`<input class='checkbox-term' id='${termData.id}' type='checkbox'/>`, termData, termData.date_added, termData.text])
    )
  }

  const initializeTable = (userData) => {
    var dt = $("#blacklist-table").DataTable({
      destroy: true,
      columns: setColumns(),
      searching: true,
      pageLength: 25,
      dom: '<"row"<"large-5 columns"f>><"table"t>r<"row bottom"<"large-3 columns"p><"large-3 columns end show-amount"l>>',
      "rowCallback": function(row, data) {
        let checkbox = row.children[0].children[0]
        checkbox.addEventListener('click',function(e) {
          handleCheckboxClicked(e)
        })

        let actionCell = row.children[1];
        if (actionCell) {
          const containerId = `action-btn-${data[1].id}`;
          actionCell.innerHTML = `<div id="${containerId}"></div>`;

          setTimeout(() => {
            const container = document.getElementById(containerId);
            if (container) {
              ReactDOM.render(
                <BlackListTermCell
                term={data[1]}
                fieldName={fieldName}
                blackListFields={blackListFields}
                handleCreate={handleCreate}/>, container);
            }
          }, 0);
        }
      }
    });
    dt.api().clear();
    for (var i = 0; i < blackListTerms.length; i++) {
      mapColumns(dt, blackListTerms[i])
    }
    dt.fnDraw()
    setIsLoading(false);
  }

  const handleCheckboxClicked = (e) => {
    setIdsFromCheckBoxToState()
    if (e.target.checked == false) { selectAllCheckboxElement().checked = false }
  }

  const setIdsFromCheckBoxToState = () => {
    let termIds = []
    allCheckBoxes().forEach( (box) => {
      let id = parseInt(box.id)
      if (box.checked == true) { termIds.push(id) }
    })
    setSelectedBoxes(termIds)
  }

  const toggleAllCheckboxes = () => {
    if (isAllBoxesChecked()) {
      unCheckAllBoxesAndClearState()
    } else {
      handleCheckAllBoxesAndSetState()
    }
  }

  const handleCheckAllBoxesAndSetState = () => {
    selectAllCheckboxElement().checked = true
    checkAllBoxesAndSetState()
  }

  const checkAllBoxesAndSetState = () => {
    let termIds = []
    allCheckBoxes().forEach( (box) => {
      box.checked = true;
      termIds.push(box.id);
    })
    setSelectedBoxes(termIds)
  }

  const isAllBoxesChecked = () => {
    let checked = document.querySelectorAll('.checkbox-term:checked').length
    let totalBoxes = document.querySelectorAll('.checkbox-term').length
    if (checked.length == 0) {return false}
    return checked == totalBoxes
  }

  return (
    <div className="campaigns-index">
      <table id="blacklist-table">
        <thead>
          <tr>
            <th><input id="select-all" type='checkbox' onClick={toggleAllCheckboxes}/></th>
            <th>Term</th>
            <th>Date added</th>
            <th/>
          </tr>
        </thead>
        <tbody></tbody>
      </table>
    </div>
  )
}

export default Table;




