import React, { useState, useEffect } from 'react';
import Dashboard from './Dashboard';
import Articles from './Articles';
import Posters from './Posters';
import Abstracts from './Abstracts';
import ReviewerTableMain from './ReviewerTableMain';
import { HashRouter as Router, Switch, Route, Link } from "react-router-dom";

const Main = ({dashboardPath, articlesPath, postersPath, abstractsPath, createPath, templatePath, reviewershipsPath, reviewerships_total, reviewing_total, invited_total, isParent, articles_dot_color, posters_dot_color, abstracts_dot_color, isSubChannel, peChannel}) => {

  const [activeTab, setActiveTab] = useState('')
  const [spinning, setSpinning] = useState(true);
  const [showArticlesDot] = useState(articles_dot_color);
  const [showPostersDot] = useState(posters_dot_color);
  const [showAbstractsDot] = useState(abstracts_dot_color);

  useEffect(() => {
    setActiveTab(window.location.hash.replace(/^#!\/?|\/$/g, ''))
  }, [])

  const renderSpinner = () => {
    if(spinning) {
      return (
        <div className='spinner-container'>
          <img src='https://public.cureus.com/ajax-loader.gif'/>
        </div>
      )
    }
  }

  const renderDot = () => {
    if(showArticlesDot.length > 0) {
      return (
        <span className={`badge ${showArticlesDot}`}/>
      )
    }
  }

  const renderPostersDot = () => {
    if(showPostersDot.length > 0) {
      return (
        <span className={`badge ${showPostersDot}`}/>
      )
    }
  }

  const renderAbstractsDot = () => {
    if(showAbstractsDot.length > 0) {
      return (
        <span className={`badge ${showAbstractsDot}`}/>
      )
    }
  }
  
  return (
    <Router hashType={"hashbang"}>
      <div className="small-12 columns new-channel-container">
        <div className="row">
          <div className="large-12 columns secondary-tabs-channel">
            <div className="cureus-tabs secondary-tabs">
              <div className="tabs-container">
                <div className="tab-items">
                  <ul>
                  <Link to="/">
                      <li className={"tab-title inline " + (activeTab === '' ? 'active' : '')} onClick={() => setActiveTab('')}>
                        <a className="dashboard">DASHBOARD</a>
                      </li>
                    </Link>
                    <Link to="/articles">
                      <li className={"tab-title inline " + (activeTab === 'articles' ? 'active' : '')} onClick={() => setActiveTab('articles')}>
                        <a className="dashboard">ARTICLES {renderDot()}</a>
                      </li>
                    </Link>
                    <Link to="/abstracts">
                      <li className={"tab-title inline " + (activeTab === 'abstracts' ? 'active' : '')} onClick={() => setActiveTab('abstracts')}>
                        <a className="dashboard">ABSTRACTS {renderAbstractsDot()}</a>
                      </li>
                    </Link>
                    <Link to="/posters">
                      <li className={"tab-title inline " + (activeTab === 'posters' ? 'active' : '')} onClick={() => setActiveTab('posters')}>
                        <a className="dashboard">POSTERS {renderPostersDot()}</a>
                      </li>
                    </Link>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
          {renderSpinner()}
          <Switch>
            <Route path='/' exact>
              <Dashboard dashboardPath={dashboardPath} setSpinning={setSpinning} peChannel={peChannel} isSubChannel={isSubChannel}/>
            </Route>
            <Route path='/articles'>
              <Articles articlesPath={articlesPath} createPath={createPath} templatePath={templatePath} setSpinning={setSpinning} isParent={isParent} peChannel={peChannel}/>
              {!peChannel && <ReviewerTableMain reviewershipsPath={reviewershipsPath} createPath={createPath} templatePath={templatePath} reviewershipsTotal={reviewerships_total} reviewingTotal={reviewing_total} invitedTotal={invited_total}/>}
            </Route>
            <Route path='/abstracts'>
              <Abstracts abstractsPath={abstractsPath} setSpinning={setSpinning} isParent={isParent} />
            </Route>
            <Route path='/posters'>
              <Posters postersPath={postersPath} setSpinning={setSpinning} isParent={isParent} />
            </Route>
          </Switch>
      </div>
    </Router>
  )

}

export default Main;