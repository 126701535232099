import React from 'react';
import {Helmet} from 'react-helmet';

const Introduction = () => {

  return (
    <React.Fragment>
      <Helmet>
        <title>Medical Journal Articles | Publish Research | Cureus</title>
        <meta name='description' content='Publish medical journal articles with Cureus, an Open Access platform for disseminating research. Learn more about submitting your article for publication.' />
      </Helmet>
    <h1>An Introduction to Publishing Medical Articles with Cureus</h1>

    <h2>Aims and Scope</h2>
    <p>The <a href='/about'>Cureus Journal</a> of Medical Science is an Open Access, multispecialty, online-only medical journal owned by Springer Nature. Cureus was founded with a mission to eliminate the barriers to the generation and dissemination of medical knowledge and thus has a global user base of authors, peer reviewers and readers.</p>

    <p>Cureus publishes all credible medical science applicable to the global healthcare community. Article credibility is determined by multiple rounds of medical journal <a href='/editorial_board'> editorial review</a> and commentary from 
    <a href='/people?preselected_roles%5B%5D=reviewer'> trusted peer reviewers</a> that includes substantial critical feedback confirming the absence of fatal scientific and methodological flaws.</p>

    <p>Five different types of articles are <a href='/author_guide#!/overview/accepted-article-types'>accepted</a> in the <a href='/newsroom/news/122'>Cureus Journal of Medical Science</a>:</p>
    <ul class='disc indented-for-medium-up'>
      <li>Original research</li>
      <li>Review articles</li>
      <li>Case reports</li>
      <li>Technical reports</li>
      <li>Editorials</li>
    </ul>
    <p>Continuous online publication ensures that all published articles are disseminated quickly to a wide audience via the journal website, PubMed Central and Web of Science.</p>

    <h2>Open Access Statement</h2>
    <p>Cureus’s method of publishing medical articles means that content is freely available to all readers worldwide immediately upon publication, ensuring maximum dissemination of articles, posters and abstracts. Additionally, readers can access all of our articles and other online content without <a href='/registrations/sign_up'>registering for an account</a>. Cureus utilizes a Continuous Article Publication (CAP) model, reducing publication times to a minimum and ensuring timely communication to the community.</p>
      
    <h2>Acceptance Policy and Editing Fees</h2>
    <p>Cureus is <a href='/about'>committed</a> to providing easy, timely and affordable article publication. We expect all submissions to adhere to our rigorous formatting and language requirements. Placing this responsibility on our authors via our DIY submission process allows many authors to publish medical articles with zero editing fees.</p>
    <p>
    Preliminary acceptance occurs when your article enters peer review, but comprehensive editorial review (and the assurance of publication) will not occur until the article is submitted for publishing after peer review. </p>
    <p>
    Articles exceeding our author or reference limits or containing too many formatting and language errors will require the purchase of our <a href='/cureus_editing_services'>Preferred Editing</a> service. However, Cureus does not charge publication fees for any articles.</p>
    
    <h2>Credible Peer Review Required</h2>
    <p>Authors should suggest unbiased and expert reviewers who will provide critical feedback on their articles. Please note that medical students may not be invited to peer review. All reviewers suggested by authors are reviewed prior to the start of peer review. Positive reviews lacking in substantial constructive feedback will be closely scrutinized by our editors and may be rejected. </p>
    <p>Think of it this way - your peer reviewers will be reviewed, therefore inviting credible reviewers is of paramount importance when submitting your article for publication.</p>
    <p>While authors are required to suggest at least five peer reviewers, at least two completed reviews from Cureus-invited reviewers are required in order to satisfy peer review. For more information on the <a href='/reviewer_guide'>Cureus peer review</a> process, please refer to our Reviewer Guide.</p>
    
    <h2>Publication Ethics and Malpractice Statement</h2>
  
    <p>As a <a href='/about_publishing'>reputable medical journal</a>, Cureus requires that all articles adhere to the highest ethical standard. Cureus does not tolerate plagiarism, data or figure manipulation, knowingly providing incorrect information, copyright infringement, inaccurate author attributions, attempts to inappropriately manipulate the peer review process, failures to declare conflicts of interest, fraud or libel. </p>

    <p>
    Cureus pledges to vigorously pursue and investigate any offending parties to ensure that their transgressions are recognized by their employer, institution and/or relevant governing body. For additional, detailed information on Cureus policies regarding publication ethics and malpractice,  please refer to the <a href='/author_guide#!/policies-and-procedures/plagiarism-misconduct-and-ethics'>Plagiarism, Misconduct and Ethics section</a> prior to submitting articles for publication.

    </p>
    
    <h2>PubMed Central Indexing Requirements</h2>
    <p>The publishing requirements for all<a href='/about'> Cureus medical journal</a> articles exist in part to ensure that each published medical article is accepted for indexing in PubMed Central (PMC). All stated requirements must be met in order for your article to be published in Cureus and indexed in PMC. Articles published in Cureus will appear in PubMed Central approximately six weeks after publication.</p> 
    </React.Fragment>
  )
  
}

export default Introduction