import React, { useState, useEffect } from 'react';
import DateSelector from './DateSelector';
import BarChart from './BarChart';
import StackedBarChart from './StackedBarChart';
import LineChart from './LineChart';
import PieChart from './PieChart';
import ScatterChart from './ScatterChart';
import ProgressStats from './ProgressStats';
import ActivityHistory from './ActivityHistory';
import ClaimedArticles from './ClaimedArticles';
import MetricsApi from '../../Cureus/Admin/MetricsApi';
import MatchedArticles from './MatchedArticles';
import DeskSubmissions from './DeskSubmissions';
import ApprovalEditorsByArticleCategory from './ApprovalEditorsByArticleCategory';

const Chart = ({ title, labels, dateRanges, path, chartType, name, yLabel, xLabel, editors, contentType, tableOptions, description, blurb }) => {

  const [response, setResponse] = useState(null);
  const [startDate, setStartDate] = useState(dateRanges[0].from)
  const [endDate, setEndDate] = useState(dateRanges[0].to)
  const [selectedRange] = useState(dateRanges[0].label);
  const [editorType, setEditorType] = useState(null);
  const [editorId, setEditorId] = useState(null);
  const [tableParams, setTableParams] = useState({})

  useEffect(() => {
    getStats(startDate, endDate, selectedRange);
  }, [editorType, editorId, tableParams])

  function getStats(fromDate, toDate, selectedRange) {
    $(`#${name}-container`).spin();
    var params = {from: fromDate, to: toDate, name: name, editorType: editorType, editorId: editorId, tableParams: tableParams, contentType: contentType};
    MetricsApi.makeRequest(path, 'POST', params, function(res) {
      setStartDate(res.startDate)
      setEndDate(res.endDate);
      setResponse(res);
      $(`#${name}-container`).spin(false);
    })
  }

  function renderChart() {
    var datasets = response !== null ? response.datasets : []
    switch(chartType) {
      case 'bar':
        return <BarChart
          ctx={ name }
          labels={ labels }
          datasets={ datasets }
          title={ title }
          yLabel={ yLabel }
          xLabel={ xLabel }
        />
      case 'line':
        return <LineChart
          ctx={ name }
          labels={ labels }
          datasets={ datasets }
          title={ title }
          yLabel={ yLabel }
          xLabel={ xLabel }
        />
      case 'pie': 
        return <PieChart
          ctx={ name }
          labels={ labels }
          datasets={ datasets }
          title={ title }
        />
      case 'scatter': 
        return <ScatterChart
          ctx={ name }
          labels={ labels }
          datasets={ datasets }
          title={ title }
          yLabel={ yLabel }
          xLabel={ xLabel }
        />
      case 'table':
        return <ActivityHistory 
                  datasets={ datasets } 
                  getStats={getStats}
                  setEditorType={setEditorType}
                />
      case 'ClaimedArticles':
        datasets = response !== null ? response.datasets : {data: [], headers: [], keys: []}
        return <ClaimedArticles
                  contentType={ contentType }
                  datasets={ datasets }
                  getStats={getStats}
                />
      case 'DeskSubmissions':
        datasets = response !== null ? response.datasets : {data: [], headers: [], keys: []}
        return <DeskSubmissions
                  datasets={ datasets }
                  getStats={getStats}
                  startDate={startDate}
                  endDate={endDate}
                />
      case 'stackedBar':
        datasets = response !== null ? response : {datasets: [], labels: []}
        return <StackedBarChart
                  ctx={ name }
                  labels={ datasets.labels }
                  datasets={ datasets.datasets }
                  title={ title }
                  yLabel={ yLabel }
                  xLabel={ xLabel }
                  contentType={ contentType }
                />
      case 'MatchedArticles':
        datasets = response !== null ? response.datasets : {data: [], headers: [], keys: []}
        return <MatchedArticles
                  datasets={ datasets }
                />
      case 'ApprovalEditorsByArticleCategory':
        datasets = response !== null ? response.datasets : {data: [], headers: [], keys: []}
        return <ApprovalEditorsByArticleCategory 
                  datasets={datasets}
                />
    }
  }

  function renderDateSelector() {
    if(startDate) {
      return (
        <div className='large-2 columns'>
          <DateSelector
            from={startDate}
            to={endDate}
            getStats={getStats}
            selectedRange={selectedRange}
            dateRanges={dateRanges}
          />
        </div>
      )
    }
  }

  function renderProgressStats() {
    if(name === 'ArticlesInProgress') {
      return (
        <ProgressStats name={name} progressPath={path}/>
      )
    }
  }

  function renderTitle() {
    if(title === "Articles Submitted vs. Approved for Publication") {
      return <React.Fragment>
        <h4>Articles Submitted vs.</h4>
        <h4>Approved for Publication</h4>
      </React.Fragment>
    } else {
      return (
        <>
          <h4>{title}</h4>
          {blurb && <p>{blurb}</p>}
        </>
        )
      }
    }

  function renderEditorSelector() {
    if(editors) {
      return(
        <div className="large-4 large-offset-8 columns" style={{marginTop: '16px'}}>
          <select onChange={e => setEditorId(e.target.value)}>
            <option value="">Total</option>
            { 
                editors.map((editor) => {
                return <option value={editor.id}>{editor.name}</option>
              })
            }
          </select>
        </div>
      )
    }
  }

  function renderDescription() {
    if (description) {
      return (<p>{description}</p>)
    }
  }

  var offset = startDate ? ' ' : ' large-offset-1 '

  return (
    <React.Fragment>
      <div className='header'>
        <div className={"large-10" + offset + "columns"} style={{textAlign: 'center'}}>
          { renderTitle() }
        </div>
      </div>
        { renderDateSelector() }
        { renderDescription() }
      <div>
        { renderEditorSelector() }
        { renderChart() }
        { renderProgressStats() }
      </div>
    </React.Fragment>
  )

}

export default Chart;