import React, { useState, useEffect } from 'react';
import Draft from './ArticleStates/Draft';
import EditorScreening from './ArticleStates/EditorScreening';
import EditorPreview from './ArticleStates/EditorPreview';
import EditorPreviewDeferred from './ArticleStates/EditorPreviewDeferred';
import EditorCheck from './ArticleStates/EditorCheck';
import InReview from './ArticleStates/InReview';
import ReviewsComplete from './ArticleStates/ReviewsComplete';
import ChannelEditorApproval from './ArticleStates/ChannelEditorApproval';
import ChannelEditorApprovalDeferred from './ArticleStates/ChannelEditorApprovalDeferred';
import CureusEditorApproval from './ArticleStates/CureusEditorApproval';
import CureusEditorApprovalDeferred from './ArticleStates/CureusEditorApprovalDeferred';
import Approved from './ArticleStates/Approved';
import Published from './ArticleStates/Published';
import DeskRejected from './ArticleStates/DeskRejected';
import Blocked from './ArticleStates/Blocked';
import Abandoned from './ArticleStates/Abandoned';
import All from './ArticleStates/All';
import EditorCheckDeferred from './ArticleStates/EditorCheckDeferred';
import InRereview from './ArticleStates/InRereview';
import RejectedChannelScreening from './ArticleStates/RejectedChannelScreening';

const Articles = ({articlesPath, setSpinning, createPath, templatePath, isParent, peChannel}) => {

  const [data, setData] = useState(null);

  useEffect(() => {
    fetchData()
  }, [])

  const fetchData = () => {
    setSpinning(true)
    $.ajax({
      type: 'GET',
      url: articlesPath,
      success: function(data) {
        setData(data)
        setSpinning(false)
        $(document).foundation('reflow');
      }
    });
  }

  const renderChevronContainer = (expanded, setExpanded, className='') => {
    return (
      <div className="chevron-arrow-container">
        <img
          src="https://public.cureus.com/channel-icons/chevron-thin-down.png"
          onClick={() => setExpanded(!expanded)}
          onMouseOver={e => (e.currentTarget.src = "https://public.cureus.com/channel-icons/chevron-hovered-down.png")}
          onMouseOut={e => (e.currentTarget.src = "https://public.cureus.com/channel-icons/chevron-thin-down.png")}
          onMouseDown={e => (e.currentTarget.src = "https://public.cureus.com/channel-icons/chevron-clicked-down.png")}
          onMouseUp={e => (e.currentTarget.src = "https://public.cureus.com/channel-icons/chevron-hovered-down.png")}
          className={`chevron-toggle ${className} ${expanded ? "rotate" : ""}`}
        />
      </div>
    )
  }

  const renderMailIcon = (state) => {
    return (
      <div className="button primary small bulk-email-button disabled has-tip tip-left" data-tooltip="" title="Select one or more checkboxes and click this button to send email reminders." id={"mail-tooltip-" + state}>
        <i className="fa fa-envelope"></i>
      </div>
    )
  }

  if (!data) {
    return <div/>
  } else {
    return (
      <React.Fragment>
        <h5 className="articles-header">Articles <span>({data.total})</span></h5>
        <div className="articles-container">
          <Draft renderChevronContainer={renderChevronContainer} articlesPath={articlesPath} initialData={data.initial_data.draft} isParent={isParent} renderMailIcon={renderMailIcon} />
          {!peChannel && <EditorPreview renderChevronContainer={renderChevronContainer} articlesPath={articlesPath} initialData={data.initial_data.editor_preview} isParent={isParent} renderMailIcon={renderMailIcon} createPath={createPath} templatePath={templatePath} />}
          {peChannel && <EditorScreening renderChevronContainer={renderChevronContainer} articlesPath={articlesPath} initialData={data.initial_data.editor_screening} isParent={isParent} renderMailIcon={renderMailIcon} createPath={createPath} templatePath={templatePath} />}
          {peChannel && <RejectedChannelScreening renderChevronContainer={renderChevronContainer} articlesPath={articlesPath} initialData={data.initial_data.rejected_channel_screening} isParent={isParent} renderMailIcon={renderMailIcon} createPath={createPath} templatePath={templatePath} />}
          {!peChannel && <EditorPreviewDeferred renderChevronContainer={renderChevronContainer} articlesPath={articlesPath} createPath={createPath} templatePath={templatePath} initialData={data.initial_data.editor_preview_deferred} isParent={isParent} renderMailIcon={renderMailIcon} />}
          <EditorCheck renderChevronContainer={renderChevronContainer} articlesPath={articlesPath} initialData={data.initial_data.editor_check} isParent={isParent} renderMailIcon={renderMailIcon} />
          <EditorCheckDeferred renderChevronContainer={renderChevronContainer} articlesPath={articlesPath} createPath={createPath} templatePath={templatePath} initialData={data.initial_data.editor_check_deferred} isParent={isParent} renderMailIcon={renderMailIcon} />
          <InReview renderChevronContainer={renderChevronContainer} articlesPath={articlesPath} createPath={createPath} templatePath={templatePath} initialData={data.initial_data.in_review} isParent={isParent} renderMailIcon={renderMailIcon}/>
          <InRereview renderChevronContainer={renderChevronContainer} articlesPath={articlesPath} createPath={createPath} templatePath={templatePath} initialData={data.initial_data.in_rereview} isParent={isParent} renderMailIcon={renderMailIcon}/>
          <ReviewsComplete renderChevronContainer={renderChevronContainer} articlesPath={articlesPath} createPath={createPath} templatePath={templatePath} initialData={data.initial_data.reviews_complete} isParent={isParent} renderMailIcon={renderMailIcon}/>
          {!peChannel && <ChannelEditorApproval renderChevronContainer={renderChevronContainer} articlesPath={articlesPath} initialData={data.initial_data.channel_editor_approval} isParent={isParent} renderMailIcon={renderMailIcon} createPath={createPath} templatePath={templatePath}/>}
          {!peChannel && <ChannelEditorApprovalDeferred renderChevronContainer={renderChevronContainer} articlesPath={articlesPath} createPath={createPath} templatePath={templatePath} initialData={data.initial_data.channel_editor_approval_deferred} isParent={isParent} renderMailIcon={renderMailIcon}/>}
          <CureusEditorApproval renderChevronContainer={renderChevronContainer} articlesPath={articlesPath} initialData={data.initial_data.cureus_editor_approval} isParent={isParent} renderMailIcon={renderMailIcon}/>
          <CureusEditorApprovalDeferred renderChevronContainer={renderChevronContainer} articlesPath={articlesPath} createPath={createPath} templatePath={templatePath} initialData={data.initial_data.cureus_editor_approval_deferred} isParent={isParent} renderMailIcon={renderMailIcon}/>
          <Approved renderChevronContainer={renderChevronContainer} articlesPath={articlesPath} createPath={createPath} templatePath={templatePath} initialData={data.initial_data.approved} isParent={isParent} renderMailIcon={renderMailIcon}/>
          <Published renderChevronContainer={renderChevronContainer} articlesPath={articlesPath} initialData={data.initial_data.published} isParent={isParent} renderMailIcon={renderMailIcon}/>
          <DeskRejected renderChevronContainer={renderChevronContainer} articlesPath={articlesPath} initialData={data.initial_data.desk_rejected} isParent={isParent} renderMailIcon={renderMailIcon}/>
          <Blocked renderChevronContainer={renderChevronContainer} articlesPath={articlesPath} initialData={data.initial_data.blocked} isParent={isParent} renderMailIcon={renderMailIcon}/>
          <Abandoned renderChevronContainer={renderChevronContainer} articlesPath={articlesPath} initialData={data.initial_data.abandoned} isParent={isParent} renderMailIcon={renderMailIcon}/>
          <All renderChevronContainer={renderChevronContainer} articlesPath={articlesPath} initialData={data.initial_data.all} isParent={isParent} renderMailIcon={renderMailIcon}/>
        </div>
      </React.Fragment>

    )
  }
}

export default Articles;