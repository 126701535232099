import React, { useEffect, useState } from 'react';
import Modal from '../../Modal';

const MessageStatusModal = ({ messageId, articleId, articleTitle }) => {

  const [messageStatuses, setMessageStatuses] = useState([]);
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    setLoading(true)
    fetchMessageStatuses(articleId, messageId)
  }, [])

  const renderDate = (date) => {
    return <p className='small-4 columns centered'dangerouslySetInnerHTML={{ __html: date }}/>
  }

  const renderMessageStatusTable = () => {

    if (messageStatuses.length === 0) {
      return <p>No status history found.</p>;
    }

    const TableHeader = () => (
      <div className='row'>
        <strong className='small-4 columns centered'>Status</strong>
        <strong className='small-4 columns centered'>Updated By</strong>
        <strong className='small-4 columns centered'>Date</strong>
      </div>
    );

    const TableRow = ({ status, user, updated_at }) => (
      <div className='row'>
        <p className='small-4 columns centered'>{status}</p>
        <p className='small-4 columns centered'>{user}</p>
        {renderDate(updated_at)}
      </div>
    );

    return (
      <div>
        <br />
        <TableHeader />
        <br />
        {messageStatuses.map((status, index) => (
          <TableRow key={index} {...status} />
        ))}
      </div>
    );
  };

  const renderSpinner = () => {
    if (loading) {
      return (
        <div className='spinner-container'>
          <img src='https://public.cureus.com/ajax-loader.gif'/>
        </div>
      )
    }
  }

  const fetchMessageStatuses = (articleId, messageId) => {
    $.ajax({
      type: 'GET',
      dataType: 'json',
      url: `/admin/articles/${articleId}/messages/${messageId}/fetch_message_statuses`,
      success: function(data) {
        setLoading(false)
        setMessageStatuses(data.data)
      }
    });
  }

  return (
    <div className="reveal-modal medium" data-reveal data-options="close_on_background_click:true;">
      <div className="modal-body">
        <div className="modal-content">
          <h4>Message Status History</h4>
          <p>Article: <a href={`/admin/articles/${articleId}`} target="_blank">{articleTitle}</a></p>
          <div>
            <div>
              {renderSpinner()}
              {!loading && renderMessageStatusTable()}
            </div>
          </div>
          <div className='modal-footer'>
            <a onClick={() => Modal.close()} className="button primary">OK</a>
          </div>
        </div>
      </div>
    </div>
  )
}

export default MessageStatusModal;