import React, { useState, useEffect } from 'react';
import EditorialWidgetBody from './EditorialWidgetBody';
import DateSelector from './DateSelector';

const LineItemWidget = ({ dateRanges, isChannelDashboard, queryParams, path, defaultDashboard, displayFlex, color, isCompetition }) => {
  const initialRangeIndex = isChannelDashboard ? 4 : 0;
  const [selectedRange, setSelectedRange] = useState(dateRanges[initialRangeIndex].label);
  const [from, setFrom] = useState(dateRanges[initialRangeIndex].from);
  const [to, setTo] = useState(dateRanges[initialRangeIndex].to);
  const [body, setBody] = useState([]);
  const [head, setHead] = useState(null);

  useEffect(() => {
    getStats(from, to, selectedRange);
  }, []);

  const getStats = (fromDate, toDate, selectedRange) => {
    setBody([])
    Object.keys(queryParams).forEach((label) => {
      let position = queryParams[label];
      setFrom(fromDate);
      setTo(toDate);
      setSelectedRange(selectedRange);
      $.ajax({
        type: 'get',
        url: path,
        data: { from: fromDate, to: toDate, default_dashboard: defaultDashboard, label: label , position: position, line_items: true},
        success: function (res) {
          setBody(prevBody => {
            const newSortedBody = [...prevBody, res.body].sort((a, b) => a.position - b.position);
            return newSortedBody;
          });
          
          setHead(res.head);
        },
        error: function (e) {
          console.error(e);
        }
      });
    });
  };

  const renderHeader = () => {
    if (!head) return null;
    return (
      <div style={displayFlex ? { display: 'flex' } : {}}>
        <h6><i className={head.icon} />{head.title}</h6>
      </div>
    );
  };

  const renderBody = () => {
    if (!body.length) return null;
    return <EditorialWidgetBody stats={body} isChannel={isChannelDashboard || isCompetition} />;
  };

  return (
    <div className='row'>
      <div className='large-12 columns'>
        <div className='dashboard-published-content-wrap' style={{ backgroundColor: color }}>
          <div className='row'>
            <div className='large-10 columns'>
              <div className='published-content-stats-wrap'>
                {renderHeader()}
              </div>
            </div>
            <div className='large-2 columns'>
              <DateSelector
                from={from}
                to={to}
                getStats={getStats}
                selectedRange={selectedRange}
                dateRanges={dateRanges}
              />
            </div>
          </div>
          {body && (
            <div>
              <hr />
              <div className='row'>
                <div className='large-12 columns'>
                  {renderBody()}
                </div>
              </div>
              <hr />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default LineItemWidget;
