
import React, { useState, useEffect } from 'react';

const ChannelScreeningModal = ({articleId, channelId}) => {

  let param = $("[name='csrf-param']").attr("content");
  let token = $("[name='csrf-token']").attr("content");

  return (
    <div className="reveal-modal tiny" data-reveal id="screening-modal">
    <div className="modal-body">
      <div className='modal-close'>
        <a href='#' className='cancel-modal'>
          <img src="https://public.cureus.com/close_x.png"/>
        </a>
      </div>

      <div className="modal-content text-center">
        <h4 className='reg'>Does this article belong to your channel?</h4>
      </div>

      <div className="modal-footer text-center">
        <form action={`/admin/channels/${channelId}/articles/${articleId}/notify_editor_for_peer_review`} method="post">
          <input type="hidden" name={ param } value={ token } />
          <input type="hidden" name="_method" value="put" />
          <button type="submit" className="button success">Approve</button>
        </form>
        <form action={`/admin/channels/${channelId}/articles/${articleId}/notify_editor_for_peer_review`} method="post">
          <input type="hidden" name={ param } value={ token } />
          <input type="hidden" name="_method" value="put" />
          <input type="hidden" name="reject" value="true" />
          <button type="submit" className="button alert">Reject</button>
        </form>
      </div>
    </div>
  </div>
  )
}


export default ChannelScreeningModal;


