import React, { useState, useEffect } from 'react';
import { HashRouter as Router } from "react-router-dom";
import NavItems from '../NavItems';
import TopSection from '../TopSection'
import LeftSidebar from '../LeftSidebar/Sidebar';
import Ad from '../../Shared/Ad';


const Main = ({article, articleHtml, currentUserId, preview, retracted, heroImage, page, sidebarAd, bannerAdBottom, bannerAdTop, channelLogo, channelTitle, channelPath}) => {

  useEffect(() => {
    window.addEventListener('resize', () => handleResize(70));
    let loadAdjustment = preview || page !== 'article' ? 7 : 0
    setTimeout(function() {
      handleResize(70 + loadAdjustment)
    }, 500)
    if(typeof(bannerAdBottom) !== 'object') {
      $('#banner-ad-2').append(bannerAdBottom)
    }
  }, [])

  const handleResize = (offset) => {
    document.querySelector('.top-section').style.height = document.querySelector('.article-header-container').offsetHeight + offset + 'px';
  }

  const renderBannerAd = (props, id) => {
    if(typeof(props) === 'object') {
      return (
        <div id={id}>
          <div className='cureus-ad-wrapper page-banner-ad' >
            <Ad
              props={ props }
              size={ "banner" }
            />
          </div>
        </div>
      )
    } else {
        return (
          <div id={id}/>
        )
    }
  }

  const renderAbstract = () => {
    return (
      <div className="row small-collapse">
        <div className="row">
          <div className="small-12 columns" style={{position: 'inherit', marginBottom: '70px', marginTop: '70px'}}>
            <div className="tab-content-container">
              <h3 className="reg">Abstract</h3>
              <div dangerouslySetInnerHTML={{__html: article.abstract}}/>
              <h3 style={{textAlign: 'center'}}>Please claim the article from your dashboard in order to unlock full access.</h3>
            </div>
          </div>
        </div>
      </div>
    )
  }

  const renderAuthors = () => {
    return (
      <div className="row">
        <div className="small-12 columns">
          <div className="tab-content-container authors-tab">
            <div dangerouslySetInnerHTML={{__html: articleHtml["authors"]["html"]}}/>
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className="new-article-show">
      <div className="row small-collapse">
      <Router hashType={"hashbang"}>
        <LeftSidebar
          page={page}
          article={article}
          preview={preview}
          retracted={retracted}
          sidebarAd={sidebarAd}
        />
        <div className={"medium-12 small-12 columns end " +  (preview ? "large-10 large-offset-2" : "large-8")}>
          <TopSection
              html={articleHtml["hero"]["html"]}
              heroImage={heroImage}
              articleId={article.id}
              currentUserId={currentUserId}
              preview={preview}
              bannerAdTop={bannerAdTop}
              channelLogo={channelLogo}
              channelTitle={channelTitle}
              channelPath={channelPath}
            />
          {renderBannerAd(bannerAdTop, 'banner-ad-1')}
          {renderAbstract()}
          {renderAuthors()}
        </div>
      </Router>
      </div>
    </div>
  )

}

export default Main;