import React, { useEffect, useState } from 'react';

const DeskRejectedModal = ({data, startDate, endDate, type}) => {
  const [rejected, setRejected] = useState([]);
  useEffect(() => {
    $.ajax({
      type: 'GET',
      data: {
        editorId: data.id,
        startDate: startDate,
        endDate: endDate,
        type,
      },
      url: '/admin/metrics/fetch_desk_rejected_articles',
      success: (res) => {
        setRejected(res.rejected)
      }
    })
  }, [])

  const modalHeader = type === 'deskRejected' ? `${data.full_name}: Desk Rejected` : `${data.full_name}: Blocked`

  return (
    <div className="reveal-modal" data-reveal>
      <div className='modal-body'>
        <div className='modal-header'>
          <h5>{modalHeader}</h5>
        </div>
        <div className='modal-close'>
          <a
            href='#'
            className='cancel-modal'>
            <img src='https://public.cureus.com/close_x.png'/>
          </a>
        </div>

        <div className='modal-content'>
          <div className='row'>
            <div className='rejected-modal-content'>
              {rejected.length > 0 &&
                rejected.map((article, index) => {
                  return (
                    <a
                      key={index}
                      href={`/admin/articles/${article.target_id}`}
                      target='_blank'
                    >
                      {article.target_id},
                    </a>
                  )
                })
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default DeskRejectedModal;