import React from 'react';
import EditorMessagesTable from './EditorMessagesTable';

const ClosedTable = ({indexPath, updateTablePath,status}) => {
  return (
    <div className='main'>
      <EditorMessagesTable indexPath = {indexPath} updateTablePath = {updateTablePath} status={status}/>
      </div>
  )
}

export default ClosedTable;