import React, {useState, useEffect} from 'react';
import DynamicJqueryTable from '../Shared/DynamicJqueryTable';

const AeHistoryModal = ({editorId, editorName}) => {

  useEffect(() => {
    const handleToggle = function() {
      const $this = $(this);
      if ($this.hasClass('expanded')) {
        $this.removeClass('expanded');
        $this.text($this.data('truncated-msg'));
      } else {
        $this.addClass('expanded');
        $this.text($this.data('full-msg'));
      }
    };

    const handleScroll = function() {
      window.scrollTo(0, 0)
    }

    $(document).on('click', '.paginate_button', handleScroll);
    $(document).on('click', '.toggle-message', handleToggle);
    return () => {
      $(document).off('click', '.paginate_button', handleScroll);
      $(document).off('click', '.toggle-message', handleToggle);
    };
  }, []);


  const emailHistoryColumns = [
    { 
      title: 'emailed_date',
      data: 'email_date',
      visible: false,
      orderable: false
    },
    { title: 'Date', data: 'date'},
    { title: 'Sender', data: 'sender_name'},
    { title: 'Table', data: 'table_origin', class: 'centered'},
    { title: 'Message',
      data: 'message',
      width: '400px',
      render: (row) => {
        const fullMessage = row.message.full_message || '';
        const truncated = row.message.truncated || '';

        return `
          <div style="width:360px; white-space: pre-line;">
            <span
              class="toggle-message"
              style="cursor: pointer;"
              data-full-msg="${fullMessage}"
              data-truncated-msg="${truncated}"
            >
              ${row.message.truncated}
            </span>
          </div>
        `;
      }
    }
  ]

  return (
    <div id={"history-email-reveal-modal"} className="reveal-modal middling" data-reveal>
      <div className="modal-body">
        <div className="modal-close">
          <a href='#' className='cancel-modal'>
            <img src="https://public.cureus.com/close_x.png"/>
          </a>
        </div>

        <div className="modal-content">
          <h6>{editorName}: Email History</h6>

          <DynamicJqueryTable 
            columnsConfig={emailHistoryColumns}
            path="/admin/ae_dashboards/email_history"
            tableId="email-history"
            params={{editor_id: editorId}}
            noDatepicker={true}
            disableSearch={true}
            sortProps={[0, 'desc']}
            columnDefsProp={[{ targets: [1], orderData: [0] }, { targets: [0], visible: false }]}
          />
        </div>
      </div>
    </div>
  );
};

export default AeHistoryModal;