var createReactClass = require('create-react-class');
import React from 'react';

const StateUpdateModal = createReactClass({
  componentWillMount: function() {
    this.setState({
      state: this.props.editor.state
    })
  },

  renderSelectMenu: function() {
    if (this.isChannelEditing() || this.props.isApprovalEditor) {
      return;
    }
    return (
      <select defaultValue={this.state.state} onChange={this.saveSelectOptionToState}>
        {
          this.returnSelectOptions()
        }
      </select>
    )
  },

  returnSelectOptions: function() {
    return ["unstarted", "started", "completed"].map(function(state) {
      return <option key={state} value={ state } >
              { state.titleize() }
             </option>
    })
  },

  saveSelectOptionToState: function(e) {
    var value = e.target.value
    this.setState({
      state: value
    })
  },

  updateState: function() {
    var self = this;
    return function() {
      self.props.updateState({
        ownershipId: self.props.editor.ownership_id,
        newState: self.state.state,
        manuallyChanged: true,
        verify: false
      })
      $('#editor-update-modal').foundation('reveal', 'close');
    }
  },

  destroy: function() {
    var self = this;
    return function() {
      if (self.props.isJunior) { return; }
      self.props.destroy(self.props.editor);
      if (self.props.editor.user_type === "ChannelAdmin" || self.props.editor.user_type === "DeputyEditor") {
        self.disableApproveAndDeferButton();
      }
      if (self.props.editor.user_type === "ApprovalEditor") {
        self.disableApprovalEditorButtons();
      }
        $('#editor-update-modal').foundation('reveal', 'close');
    }
  },

  disableApprovalEditorButtons: function() {
    [".desk-rejection-button", ".request-re-review-modal-button", ".deferral-modal-button", ".email-author-modal"].forEach( (className) => {
      $(className).addClass("disabled");
      $(className).attr("disabled", true)
    })
  },

  disableApproveAndDeferButton: function() {
    let approveButton = document.querySelector('.approve-for-review') || document.querySelector('.approve-for-publication-channel');
    let deferButton = document.querySelector('.deferral-modal-button');
    if(approveButton && deferButton) {
      approveButton.toggleClass('disabled');
      approveButton.setAttribute('disabled', 'true');
      deferButton.toggleClass('disabled');
      deferButton.setAttribute('disabled', 'true');
    }
  },

  renderDeleteButton: function() {
    if (this.props.editor.content_type != 'copy' || this.props.isAdmin) {
      return (
        <a href="javascript:void(0)" className={this.props.isJunior ? "button alert pull-left disabled" : "button alert pull-left"} onClick={this.destroy()}> Delete </a>
      )
    }
  },

  isChannelEditing: function() {
    if(['channel_preview', 'channel_approval', 'channel_screening'].includes(this.props.editor.content_type)) {
      return true;
    }
    return false;
  },

  render: function() {
    return (
      <div className="reveal-modal middling" data-reveal id="editor-update-modal">
        <div className="modal-body">
          <div className='modal-close'>
            <a href='#' className='cancel-modal'>
              <img src="https://public.cureus.com/close_x.png"/>
            </a>
          </div>

          <div className="modal-content">
            <h4 className='reg'>Update Editor Status</h4>
            { this.renderSelectMenu() }
          </div>

          <div className="modal-footer">
            {this.renderDeleteButton()}

            <a href="#" className="button secondary cancel-modal"> Cancel </a>
            {!this.isChannelEditing() && !this.props.isApprovalEditor && <a className="button primary" onClick={this.updateState()}> Proceed </a>}
          </div>
        </div>
      </div>
    )
  }
})

export default StateUpdateModal;