var createReactClass = require('create-react-class');
import React from 'react';

const CreateCampaignModal = createReactClass({
  getInitialState: function() {
    return {
      template: null
    }
  },

  renderTemplates: function() {
    var self = this;
    return self.props.templates.map(function(template) {
      return (
        <div className="large-6 columns centered templates" key={template}>
          <label>
            {self.campaignLabels()[template]}
          </label>
          <label>
            <img src={`https://public.cureus.com/${template}_thumbnail.png`} />
            <div className="radio-container">
              Select  
              <input type="radio" name="template" id={template} onClick={self.handleTemplateSelect}/>
            </div>
          </label>
        </div>
      )
    })
  },

  campaignLabels: function() {
    return {
      "marketing": "Marketing",
      "newsletter": "Newsletter",
      "message_from_editor": "Message from edtior",
      "inside_cureus": "Cureus U",
      "hubspot_marketing": "Hubspot Marketing"
    }
  },

  createCampaign: function() {
    var self = this;
    $.ajax({
      type: "POST",
      url: '/admin/campaigns',
      data: {template_name: this.state.template},
      dataType: "json",
      success: function(res) {
        window.location.replace(res.reloadPath)
      }
    })
  },

  handleTemplateSelect: function(e) {
    this.setState({template: e.target.id})
  },

  render: function() {
    return(
      <div className="reveal-modal middling" data-reveal>
        <div className="modal-body">
          <div className='modal-close'>
            <a href='#' className='cancel-modal'>
              <img src="https://public.cureus.com/close_x.png"/>
            </a>
          </div>

          <div className="modal-content">
            <h4>Select Campaign Template</h4>
            <div className="large-12 columns">
              {this.renderTemplates()}
            </div>
          </div>

          <div className="modal-footer">
            <a className='cancel-modal button secondary'>Cancel</a>
            <a className={"button primary " + (this.state.template === null ? 'disabled' : '')} onClick={this.createCampaign}>Create Campaign</a>
          </div>
        </div>
      </div>
    )
  }
})

export default CreateCampaignModal;

