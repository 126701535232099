import React, { useState, useEffect } from 'react';
import Modal from '../Modal';

const UploadTermsModal = ({blackListFields, handleUploadCSV}) => {
  const [selectedFields, setSelectedFields] = useState([])
  const [selectAll, setSelectAll] = useState(false)
  const [showCheckboxError, setShowCheckboxError] = useState(false)
  const [showNoCsvError, setShowNoCsvError] = useState(false)


  useEffect(() => {
    setSelectAll(false)
    setSelectedFields(blackListFields)
  }, [])

  const [fileData, setFileData] = useState({})

  const renderCheckboxes = () => {
    let checkboxes = renderBlackListFieldsCheckboxes()
    checkboxes.unshift(renderSelectAllCheckbox())
    return checkboxes
  }

  const renderSelectAllCheckbox = () => {
    return (
      <div className="checkbox-container">
        <input type="checkbox" id="modal-select-all" checked={selectAll} value={selectAll} onChange={checkAllBoxes}/>
        <label htmlFor="modal-select-all">Select all</label>
      </div>
    )
  }

  const checkAllBoxes = (e) => {
    setSelectedFields(selectedFields.map(field => { return {name: field.name, checked: !selectAll} }))
    setSelectAll(!selectAll);
  }

  const renderBlackListFieldsCheckboxes = () => {
    return blackListFields
      .map((field, index) => {
        let displayName = capitalize(field.name); 
        if (field.name === 'bio') {
          displayName = 'About'
        }

      let name = field.name;
      let isChecked = isFieldCheckboxChecked(name);

      return (
        <div key={index} className="checkbox-container">
          <input type="checkbox" id={name} name={name} checked={isChecked} value={isChecked} onChange={() => handleCheckboxChange(name)}/>
          <label htmlFor={name}>{displayName}</label>
        </div>
      )
    })
  }

  const handleCheckboxChange = (name) => {
    let updatedSelectedFields = selectedFields.map(field => {
      if (field.name === name) {
        return {name: field.name, checked: !field.checked}
      } else {
        return field
      }
    })
    setSelectedFields(updatedSelectedFields)
  }

  const isFieldCheckboxChecked = (name) => {
    return selectedFields.find(field => field.name === name)?.checked
  }

  const capitalize = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  const handleAddButtonClick = () => {
    if (isCsvUploaded() && isCheckboxSelected()) {
      setShowNoCsvError(false)
      setShowCheckboxError(false)
      handleUploadCSV(fileData, selectedFields)
      Modal.close()
    }
  }

  const isCheckboxSelected = () => {
    let isSelected = selectedFields.filter(field => field.checked).length > 0
    if (!isSelected) {
      setShowCheckboxError(true)
    }
    return isSelected
  }

  const isCsvUploaded = () => {
    let uploaded = !(typeof fileData.name == 'undefined' )
    if (!uploaded) {
      setShowNoCsvError(true)
    }
    return uploaded
  }


  return (
    <div className="reveal-modal small unwanted-accounts-modal" data-reveal>
      <div className="modal-body">
        <div className='modal-close'>
          <a href='#' className='cancel-modal'>
            <img src="https://public.cureus.com/close_x.png"/>
          </a>
        </div>
        <div className="modal-content middling">
          <div className="modal-header text-center">
            <h5>Upload a List</h5>
          </div>
          <hr/>

          <div className="modal-content">
            <div className='modal-form'>
              <p>Important! Only CSV files are accepted. Please include word/phrase and then select where the terms should be blacklisted.</p>
              <input type="file" onChange={(e) => {setFileData(e.target.files[0])}  }/>
              {showCheckboxError && <p class="id-error-message">At least one checkbox must be selected</p>}
              {showNoCsvError && <p class="id-error-message">Please upload a CSV file</p>}
                <div>
                  <ul>
                    {renderCheckboxes()}
                  </ul>
                </div>

              <div className="form-actions text-center">
                <a className='cancel-modal button special'>CANCEL</a>
                <a className='button primary' onClick={handleAddButtonClick}>Add</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default UploadTermsModal
