var createReactClass = require('create-react-class');
import React from 'react';
import { Cureus } from '../../Cureus';
// Import this file to ensure the AssociationAutocomplete is added to Cureus.Utils
import '../../Cureus/Utils/AssociationAutocomplete';


const DepartmentAutoSuggester = createReactClass({
  componentDidMount: function (){
    this.initAutoSuggest()
  },

  componentDidUpdate: function(prevProps) {
    if(prevProps.institutionName !== this.props.institutionName) {
      this.initAutoSuggest()
    }
  },

  initAutoSuggest: function() {
    var $input;
    $input = $(this.refs.autosuggest).find("input");
    Cureus.Utils.AssociationAutocomplete.setup($input, {
      autocompleteUrl: this.props.deptAutocompletePath,
      institution: this.props.institutionName
    });
  },


  render: function (){
    return (
      <div ref="autosuggest">
        { this.props.children }
      </div>
    )
  }
});

export default DepartmentAutoSuggester;
