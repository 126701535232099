import React, { useState, useEffect } from 'react';
import { Cureus } from '../../Cureus';
import ArticleExpanded from './ArticleExpanded';
import CoauthoredArticle from './CoauthoredArticle';
import StateHistoryModal from '../Dashboard/StateHistoryModal';
import AbandonModal from '../Dashboard/ActionButton/AbandonModal';
import Modal from '../Modal';
import ReviewsComplete from './PrimaryButton/ReviewsComplete';
import RereviewsComplete from './PrimaryButton/RereviewsComplete';
import InReview from './PrimaryButton/InReview';
import PaidServiceModal from '../Dashboard/ActionButton/PaidServiceModal';
import Deferred from './PrimaryButton/Deferred';
import EmailEditingServiceReceiptModal from '../Dashboard/EmailEditingServiceReceiptModal';
import PublishedArticle from './PublishedArticle';
import EditorMessageModal from './EditorMessageModal';

const Article = ({ article, articleStates, authorships, authorshipEndpoint, currentUserId, email, emailReceiptEndPoint, fetchArticles, editorMessagePath, social_boost_a_id }) => {

  const [expanded, setExpanded] = useState(false);
  const [isSubmitter] = useState(article.submitterId === currentUserId)

  useEffect(() => {
    $(document).foundation('reflow');
    Cureus.SplitButton.init();
    $('body').on('mousedown touchstart', function(e) {
      let ele = document.querySelector('.has-tip.open')
      if(ele) {
        document.getElementById(ele.dataset.selector).style.display = 'none'
      }
    });

  }, [expanded])

  useEffect(() => {
    let params = (new URL(document.location)).searchParams;
    if(params.get("pe_modal") == article.id) {
      handleClickPurchase()
    }
  }, [])

  const restoreArticle = () => {
    $.ajax({
      type: 'put',
      url: `/articles/${article.id}/restore_auto_blocked_article`,
      dataType: 'json',
      success: function(){
        window.location.reload()
      },
      error: function() {
        Cureus.Flash.alert('There was an issue, please try later.')
      }
    })
  }


  const renderPrimaryActionButton = (small) => {
    if(articleStates === 'active') {
      if(article.articleState === 'draft') {
        if(article.wasDeferred && article.articleTier === 'select') {
          return renderPurchasePreferredButton()
        } else {
          return <a className="button preview-button" href={article.editArticlePath}><i className='fa fa-pencil-alt'/>Edit</a>
        }
      } else if(article.showViewCommentsButton) {
        return (
          <a className="button preview-button" href={article.articlePath} target="_blank"><i class="far fa-comments"/>View Comments</a>
        )
      } else if(article.articleState === 'approved') {
        return (
          <a className="button preview-button" href={article.previewPath}><i class="fas fa-book"/>Preview and publish</a>
        )
      } else {
        return (
          <a className="button preview-button" href={article.previewPath} target="_blank">Preview</a>
        )
      }
    } else if(articleStates === 'rejected') {
      return (
        <div className="cureus-split-dropdown-wrap" style={{display: 'inline'}}>
          <a className="secondary-button menu" data-cureus-split-dropdown={"article-menu-" + article.id + small}>
            <span><img src="https://public.cureus.com/dashboard-menu.png"/></span>
          </a>
        <ul id={"article-menu-" + article.id + small} className="cureus-split-dropdown new-dash">
            <li>
              <a className="dropdown-link" href={article.previewPath} target="_blank">Preview</a>
            </li>
            <li>
              <a className="dropdown-link" data-reveal-id={'article_' + article.id + '_block_notes_modal'}>View desk rejection</a>
            </li>
            {article.canRestore && <li>
              <a className="dropdown-link" onClick={restoreArticle}>Reinstate article</a>
            </li>}
        </ul>
      </div>
      )
    } else if(articleStates === 'blocked' && !article.autoBlocked) {
      return (
        <div className="cureus-split-dropdown-wrap" style={{display: 'inline'}}>
          <a className="secondary-button menu" data-cureus-split-dropdown={"article-menu-" + article.id + small}>
            <span><img src="https://public.cureus.com/dashboard-menu.png"/></span>
          </a>
          <ul id={"article-menu-" + article.id + small} className="cureus-split-dropdown new-dash">
            <li>
              <a className="dropdown-link" href={article.previewPath} target="_blank">Preview</a>
            </li>
            <li>
              <a className="dropdown-link" data-reveal-id={'article_' + article.id + '_block_notes_modal'}>View block notes</a>
            </li>
          </ul>
        </div>
      )
    }
  }

  const handleEmailEditingReceipt = () => {
    Modal.open({
      children: <EmailEditingServiceReceiptModal
                  id={"email-editing-service-receipt-modal-" + article.editingServiceInvoiceId}
                  editingServiceInvoiceId={article.editingServiceInvoiceId}
                  emailReceiptPath={emailReceiptEndPoint}
                  email={email}/>
    })
  }

  const renderEmailEditingReceipt = () => {
    if(article.editingServiceInvoiceId) {
      return (
        <li>
          <a href="javascript:void(0)" id={"email-editing-receipt-modal-" + article.editingServiceInvoiceId} onClick={handleEmailEditingReceipt}>Resend PE receipt</a>
        </li>
      )
    }
  }

  const renderPurchasePreferredButton = () => {
    var href, clickHandler
    href = "javascript:void(0)";
    clickHandler = handleClickPurchase;
    return (
      <a className="button preview-button" href={href} onClick={clickHandler} data-tooltip="" title="Your article requires Preferred Editing. Click to view details." id={"action-dropdown-view-tooltip-" + article.id}>Purchase</a>
    )
  }

  const renderEditorMessageIcon = () => {
    if(article.editorMessages) {
      let unseen = article.editorMessages.filter(function(message){ return !message.seen})
      let src;
      let color;
      if(unseen.length > 0) {
        color = 'red'
        src = "https://public.cureus.com/dash-editor-message-red.png"
      } else {
        color = 'blue'
        src = "https://public.cureus.com/dash-editor-message-blue.png"
      }
      return (
        <img className="editor-message-icon" src={src} onClick={() => openEditorMessageModal(color)}/>
      )
    }
  }

  const openEditorMessageModal = (color) => {
    fireAmplitude(color)
    Modal.open({
      children: <EditorMessageModal 
        editorMessages={article.editorMessages}
        title={article.title}
        id={article.id}
        fetchArticles={fetchArticles}
        editorMessagePath={editorMessagePath}
        currentUserId={currentUserId}
      />
    })
  }

  const handleClickPurchase = () => {
    Modal.open({
      children: <PaidServiceModal
                  article={article}
                  editArticlePath={article.editArticlePath}/>
    });
  }

  const handleDelete = () => {
    Modal.open({
    children: <AbandonModal
                article={article}/>
    });
  }

  const renderHamburgerMenu = (expanded, small) => {
    let listItems;
    if(article.articleState === 'editor_approval') {
      if (article.editingServiceInvoiceId) {
        listItems = renderEmailEditingReceipt()
      } else {
        return;
      }
    } else if(['draft', 'editor_check', 'editor_preview', 'editor_screening'].includes(article.articleState)) {
      if(article.wasDeferred) {
        listItems = <Deferred 
                      handleDelete={handleDelete}
                      article={article}
                      renderEmailEditingReceipt={renderEmailEditingReceipt}
                    />
      } else {
        listItems = (
          <li>
            <a className="dropdown-link" onClick={handleDelete}>Delete</a>
          </li>
        )
      }
    } else if(article.articleState === 'approved') {
      listItems = (
        <React.Fragment>
          {renderEmailEditingReceipt()}
          <li>
            <a className="dropdown-link" href={article.articlePath} target="_blank">View comments</a>
          </li>
        </React.Fragment>
      )
    } else if(article.articleState === 'in_review') {
        if(!article.canEndAuthorReviews && !article.editingServiceInvoiceId) {
          return;
        }
        listItems = <InReview
                      article={article}
                      renderEmailEditingReceipt={renderEmailEditingReceipt}
                    />
    } else if(article.articleState === 'reviews_complete' || article.articleState === 'in_rereview') {
      listItems = <ReviewsComplete 
                    handleDelete={handleDelete}
                    article={article}
                    renderEmailEditingReceipt={renderEmailEditingReceipt}
                    small={small}
                  />
    } else if(article.articleState === 'rereviews_complete') {
      listItems = <RereviewsComplete 
                    handleDelete={handleDelete}
                    article={article}
                    renderEmailEditingReceipt={renderEmailEditingReceipt}
                  />
    } else if(article.articleState === 'published') {
      listItems = (
        <React.Fragment>
          <li>
            <a className="dropdown-link" onClick={sendPublicationLetter}>Receive official publication letter</a>
          </li>
          <li>
            <a className="dropdown-link" data-reveal-id={"email-modal-" + article.id}>Email colleagues</a>
          </li>
          <li>
            <a className="dropdown-link" href={article.articlePath} target="_blank">View comments & history</a>
          </li>
          <li>
            <a className="dropdown-link" href={article.titlePath} target="_blank">View in journal</a>
          </li>
          {renderEmailEditingReceipt()}
        </React.Fragment>
      )
    }
    return (
      <div className="cureus-split-dropdown-wrap" style={{display: 'inline'}}>
        <a className={"secondary-button menu" + (expanded ? ' expanded-button' : "")} data-cureus-split-dropdown={"article-menu-" + article.id + small}>
          <span><img src="https://public.cureus.com/dashboard-menu.png"/></span>
        </a>
      <ul id={"article-menu-" + article.id + small} className="cureus-split-dropdown new-dash">
        {listItems}
      </ul>
    </div>
    )
  }

  const renderButtons = (small) => {
    if(article.articleState !== 'blocked' && article.articleState !== 'published' && isSubmitter) {
      return (
        <React.Fragment>
            {renderHamburgerMenu(false, small)}
            <div className="secondary-button expand" onClick={() => setExpanded(!expanded)}>
              <div className="caret-container">
                <i className="fas fa-caret-up"/>
              </div>
              <div className="caret-container">
                <i className="fas fa-caret-down"/>
              </div>
            </div>
        </React.Fragment>
      )
    } 
  }

  const renderLock = () => {
    if(['in_review', 'editor_check', 'editor_approval', 'approved', 'editor_preview'].includes(article.articleState)) {
      return (
        <span className="has-tip tip-bottom" data-tooltip="" id={"locked-article-notif-" + article.id} title={article.reviewsState ? article.reviewerStatusEmphasisBox : "Your article is currently locked for editing."}>
          <i class="fas fa-lock"></i>
        </span>        
      )
    }
  }

  const openStateHistoryModal = () => {
    Modal.open({
      children: <StateHistoryModal article={ article }/>
    })
  }

  const renderStateSteps = () => {
    let total = article.channel_id ? 8 : 7
    if(article.wasDeferred || article.articleState === 'blocked' || article.articleState === 'published') {
      return (
        <div className="row" style={(article.articleState === 'blocked') ? {marginTop: '18px'} : {}}>
          <div className="small-12 columns">
            <div className="state-with-badge" dangerouslySetInnerHTML={{__html: article.stateWithBadge}}/>
              <div className="article-steps">
                <a onClick={openStateHistoryModal} className="has-tip tip-top" data-tooltip="" id={"article-step-notif " + article.id} title="Click to view article status and history.">
                <span className="step has-tip tip-top" onClick={openStateHistoryModal} data-tooltip="" id={"article-step-notif " + article.id} title="Click to view article status and history." style={{fontSize: "13px"}}>View Progress</span>
                </a>
            </div>
          </div>
      </div>
      )
    } else {
      return (
        <div className="row">
          <div className="small-12 columns">
            <div className="state-with-badge" dangerouslySetInnerHTML={{__html: article.stateWithBadge}}/>
            <div className="article-steps">
              <a onClick={openStateHistoryModal} className="has-tip tip-top" data-tooltip="" id={"article-step-notif " + article.id} title="Click to view article status and history.">
                <span className="word">Step</span>
                <span className="step">&nbsp;{article.articleStep}&nbsp;</span>
                <span className="word">of&nbsp;</span>
                <span className="step">{total}</span>
              </a>
            </div>
          </div>
        </div>
      )
    }
  }

  const renderBanner = () => {
    if(article.flashMessage) {
      return (
        <div className="small-12 columns dashboard-list-item-wrap article-banner-container">
          <div dangerouslySetInnerHTML={{__html: article.flashMessage}}/>
        </div>
      )
    }
  }

  const renderAuthors = () => {
    return (
      <div className="row">
        <div className="small-12 columns author-links-container">
          <div dangerouslySetInnerHTML={{__html: article.authorListLinksMarkup}}/>
        </div>
      </div>
    )
  }

  const renderType = () => {
    if(article.articleType != 'Peer Reviewed') {
      return (
        <div className="type-container">
          <span className="type">{article.articleType}</span>
        </div>
      )
    }
  }
  
  const renderReviewers = () => {
    if(!article.reviewsState) {
      return;
    }
    let completed = article.reviews.completed
    let pending = article.reviews.reviewing
    let links = [{total: completed, name: 'completed'}, {total: pending, name: 'in progress'}]
    let newReviews;
    if(article.newReviewsCount > 0) {
      newReviews = <span className="round-icon tiny new-reviews">{article.newReviewsCount}</span>
    }
    return (
      <React.Fragment>
        <hr/>
        <div className="row">
          <div className="small-12 columns author-links-container reviews">
            <span className="legend">Reviews</span>
            {newReviews}
            {
              links.map((link, idx) => {
                return <a onClick={() => setExpanded(!expanded)}>{link.total} {link.name}{(links.length > 1 && idx === 0 ? ', ' : '')}</a>
              })
            }
          </div>
        </div>
      </React.Fragment>
    )
  }

  const renderTitle = () => {
    if(article.articleState === 'reviews_complete' || article.articleState === 'rereviews_complete') {
      return (
        <div className="title">
          <h5 className="title reg"> {renderLock()} <a target="_blank" href={article.titlePath}>{article.title || "Untitled Article"}</a></h5>
        </div>
      )
    } else {
      return (
        <div className="title">
          <h5 className="title reg"> {renderLock()} <a target="_blank" href={article.titlePath}>{article.title || "Untitled Article"}</a></h5>
        </div>
      )
    }
  }

  const fireAmplitude = (color) => {
    $.ajax("/amplitudes", {
      dataType: "json",
      type: "POST",
      data: { 
              event_type: 'editorMessageClicked', 
              user_id: currentUserId,
              source: "article-card",
              color: color
            }
    })
  }

  const sendPublicationLetter = () => {
    $.ajax({
      type: "GET",
      dataType: "json",
      url: "/dashboard/publication_letter",
      data: { 
        user_id: currentUserId,
        article_id: article.id
      },    
      success: function(res){
        Cureus.Flash.success('The official publication letter for your article has been sent to ' + res.email + '.')
        $("html, body").animate({scrollTop: 0}, 1000);
      },
    })
  }

  const smallView = () => {
    return (
      <div className="small-12 columns bordered article-box show-for-small-only">
        {renderEditorMessageIcon()}
        <div className="row title-container">
          <div className="small-12 columns">
            {renderTitle()}
          </div>
        </div>
        <div className="row">
          <div className="small-12 columns">
            {renderType()}
          </div>
        </div>
        <div className="small-view-container">
          {renderStateSteps()}
        </div>
        <div className="small-view-container">
          <div className="small-12 columns text-left">
            <div className="small-12 columns no-padding">
              {renderPrimaryActionButton('small')}
            </div>
            <div className="small-12 columns no-padding" style={{marginTop: '11px'}}>
              {renderButtons('small')}
            </div>
          </div>
        </div>
        {renderBanner()}
        {renderAuthors()}
        {renderReviewers()}
      </div>
    )
  }

  if(articleStates === 'published') {
    return (
      <PublishedArticle 
        article={article}
        renderStateSteps={renderStateSteps}
        renderAuthors={renderAuthors}
        renderHamburgerMenu={renderHamburgerMenu}
        isSubmitter={isSubmitter}
        currentUserId={currentUserId}
        social_boost_a_id={social_boost_a_id}
      />
    )
 } else if(!isSubmitter) {
    return (
      <CoauthoredArticle 
        article={article}
        isSubmitter={isSubmitter}
        renderStateSteps={renderStateSteps}
        renderAuthors={renderAuthors}
        renderReviewers={renderReviewers}
        renderType={renderType}
        renderEditorMessageIcon={renderEditorMessageIcon}
      />
    )
  } else if(!expanded) {
    return (
      <React.Fragment>
        {smallView()}
        <div className="small-12 columns bordered article-box hide-for-small">
          {renderEditorMessageIcon()}
          <div className="row title-container">
            <div className={article.articleState === 'blocked' ? "small-11 columns" : "large-8 medium-8 small-8 columns"}>
              {renderTitle()}
            </div>
            <div className={article.articleState === 'blocked'? "small-1 columns text-right" : "large-4 medium-4 small-4 columns text-right"}>
              {renderPrimaryActionButton()}
            </div>
          </div>
          <div className="row">
            <div className="large-9 medium-8 small-6 columns">
              {renderType()}
            </div>
            <div className="large-3 medium-4 small-6 columns text-right">
              {renderButtons()}
            </div>
          </div>
          {renderStateSteps()}
          {renderBanner()}
          {renderAuthors()}
          {renderReviewers()}
        </div>
      </React.Fragment>
    )
  } else {
    return (
      <ArticleExpanded 
        article={article}
        articleStates={articleStates}
        authorships={authorships}
        authorshipEndpoint={authorshipEndpoint}
        coauthorConfirmedEmphasisBox={article.coauthorConfirmedEmphasisBox}
        setExpanded={setExpanded}
        expanded={expanded}
        isSubmitter={isSubmitter}
        renderLock={renderLock}
        renderStateSteps={renderStateSteps}
        renderBanner={renderBanner}
        renderHamburgerMenu={renderHamburgerMenu}
        renderPrimaryActionButton={renderPrimaryActionButton}
        renderType={renderType}
        renderTitle={renderTitle}
        renderEditorMessageIcon={renderEditorMessageIcon}
      />
    )
  }
}

export default Article;