import {Cureus} from '../../Cureus';

Cureus.Utils.KeywordAutocomplete = {
  setup: function($fieldElem, options) {
    var lastXhr;
    var options = (options == undefined) ? {} : options;
    var autocompleteUrl = options["autocompleteUrl"] || $fieldElem.data('autocomplete-url');
    $fieldElem.autocomplete({
      source: _.debounce(function(request, response) {
        lastXhr = $.getJSON(autocompleteUrl, request, function(data, status, xhr) {
          if (xhr === lastXhr) {
            var keywordNameSuggestions = [];
            _.each(data, function(el) {
              keywordNameSuggestions.push({
                label: el.name,
                value: el.name
              });
            });
            response(keywordNameSuggestions);
          }
        });
      }, 500), 
      focus: function(event) {
        event.preventDefault();
      },
      select: function(event, ui) {
        var input = $fieldElem[0]
        var setValue = Object.getOwnPropertyDescriptor(window.HTMLInputElement.prototype, 'value').set;
        setValue.call(input, ui.item.value);
        var e = document.createEvent('Event', {bubbles: true});
        e.initEvent('input', true, true);
        input.dispatchEvent(e);

        let ele = document.querySelector('.keyword-add-button')
        if(ele) {
          ele.removeAttribute('disabled')
          document.getElementById('ui-autocomplete-input').blur()
        }

        event.preventDefault();
      }
    });
  }
};

// Make it a jQuery plugin too
(function($) {
  $.fn.keywordAutocomplete = function() {
    return this.each(function(i) {
      Cureus.Utils.KeywordAutocomplete.setup($(this));
    });
  };
})(jQuery);